import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormConfig } from '../../../../modules/forms/models/form-config.model';
import { Asset } from '../../../../modules/shared/models/asset.model';
import { Observable, of, throwError } from 'rxjs';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { RequestDto } from '../../../editor/models/dto/request-dto.model';
import { FlashMessageService } from '../../../../modules/core/services/flash-message.service';
import { SectionDto } from '../../../editor/models/dto/section-dto.model';
import { TranslateService } from '@ngx-translate/core';
import { AssetBrowserService } from '../../services/asset-browser.service';
import { AssetBrowserQuery } from '../../state/asset-browser/asset-browser.query';
import { AssetCroppingResultDto } from '../../../image-cropping/types';

@Component({
    selector: 'elias-asset-management-sidenav',
    templateUrl: './asset-management-sidenav.component.html',
})
export class AssetManagementSidenavComponent implements OnInit {
    @Input() assetId?: string;
    @Input() formConfig: FormConfig = { elements: [] };

    @Output() delete = new EventEmitter<any>();
    @Output() replace = new EventEmitter<Asset>();
    @Output() save = new EventEmitter<any>();
    @Output() assetClonedAndCropped = new EventEmitter<AssetCroppingResultDto>();

    public asset$: Observable<Asset | undefined> = of(undefined);

    constructor(
        private assetBrowserQuery: AssetBrowserQuery,
        private assetBrowserService: AssetBrowserService,
        private flashMessageService: FlashMessageService,
        private sideNavService: SideNavService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.asset$ = this.assetBrowserQuery.selectEntity(this.assetId);
    }

    public onDelete(data: any): void {
        if (!data) {
            return;
        }

        const payload: SectionDto = new SectionDto(
            {
                assetId: this.assetId,
            },
            {}
        );

        this.assetBrowserService.deleteAsset(payload).subscribe(
            () => {
                this.flashMessageService.showTranslatedInfo('propertyEditor.asset.deletefile');
            },
            (error) => {
                const displayErrorMessageFromApi = error.error.message && error.status > 0 && error.status < 500;
                if (displayErrorMessageFromApi) {
                    const errorMessage = displayErrorMessageFromApi
                        ? error.error.message
                        : this.translateService.instant('propertyEditor.remove.error');
                    this.flashMessageService.showError(errorMessage);
                } else {
                    throwError(error);
                }
            }
        );

        this.close();
        this.delete.emit(data);
    }

    public onReplace(data: Asset): void {
        this.close();
        this.replace.emit(data);
    }

    public onSave(data: any | AssetCroppingResultDto): void {
        if (!data) {
            return;
        }

        if (!data.hasOwnProperty('replaced')) {
            const payload = new RequestDto({ assetId: this.assetId }, {}, { data });
            this.assetBrowserService.updateAsset(payload).subscribe(() => {
                this.flashMessageService.showTranslatedInfo('propertyEditor.asset.success');
            });
        }

        this.save.emit(data);
        this.close();
    }

    private close(): void {
        this.sideNavService.toggle();
    }
}

<ng-container *ngIf="objectData && objectData.hasOwnProperty('image')">
    <ng-container *ngFor="let data of order; trackBy: trackByIndex">
        <div class="image-container" *ngIf="data === 'image'; else dispData">
            <div class="image-icons">
                <i class="ei ei-settings icon" (click)="openEditor()"></i>
                <i class="ei ei-crop icon" (click)="openCroppingEditor(objectData[data])"></i>
            </div>
            <elias-shared-image-preview
                [id]="objectData[data]"
                [aspectRatio]="aspectRatio"
                (click)="openEditor()"></elias-shared-image-preview>
        </div>
        <ng-template #dispData>
            <div
                class="{{ data }}"
                *ngIf="itemFieldSettings[data]"
                [innerHTML]="getData(objectData[data]) | html"></div>
        </ng-template>
    </ng-container>
</ng-container>
<ng-container *ngIf="objectKeys(objectData).length === 0">
    <elias-assetbrowser-button
        #assetBrowserButton
        buttonStyle="flat"
        [text]="'document.image.uploadSingle'"
        [single]="true"
        (assetsSelected)="onAssetsSelected($event)"></elias-assetbrowser-button>
</ng-container>
<div class="e-image-bottom-line"></div>

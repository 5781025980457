import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { exhaustMap, filter, map } from 'rxjs/operators';
import { ImagesQuery } from '../../../../editor/editor-container/state/images/images.query';
import { ImagesService } from '../../../../editor/editor-container/services/images.service';
import { RequestDto } from '../../../../editor/editor/models/dto/request-dto.model';

@Component({
    selector: 'elias-shared-image-preview',
    templateUrl: './image-preview.component.html',
})
export class ImagePreviewComponent implements OnChanges {
    @Input() aspectRatio?: string;
    @Input() id?: string;

    public imagePath$?: Observable<string>;
    public isLoading$?: Observable<boolean>;

    constructor(private imagesQuery: ImagesQuery, private imagesService: ImagesService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['id'] || changes['aspectRatio']) {
            this.updateImagePath();
        }
    }

    private updateImagePath(): void {
        this.imagePath$ = this.getImagePath();
        this.isLoading$ = this.imagePath$.pipe(map((p) => p === ''));

        if (!this.id || !this.aspectRatio) {
            return;
        }

        // Load image in a given aspect ratio if it's not there yet
        this.imagePath$
            .pipe(
                filter((path) => path === ''),
                exhaustMap(() =>
                    this.imagesService.loadSingleImage(
                        new RequestDto({
                            assetId: this.id,
                            aspectRatio: this.aspectRatio,
                        })
                    )
                )
            )
            .subscribe();
    }

    private getImagePath(): Observable<string> {
        if (!this.id) return of('');

        return this.imagesQuery.selectEntity(this.id).pipe(
            map((image) => {
                if (!image || typeof image.aspectRatios !== 'object') {
                    return '';
                }

                if (this.aspectRatio && image.aspectRatios[this.aspectRatio]) {
                    return image.aspectRatios[this.aspectRatio].content;
                }

                return '';
            })
        );
    }
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'elias-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
    @Input() path: string = '';

    get isLoading(): boolean {
        return this.path === '';
    }
}

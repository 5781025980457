<form novalidate #imageGridForm="ngForm" class="form-document image-grid-container">
    <div [dragula]="'images-draggable'" [(dragulaModel)]="images" fxLayout="row wrap" fxLayoutGap="20px grid">
        <ng-container *ngIf="objectData.length > 0">
            <div class="grid-container" *ngFor="let image of objectData; let i = index" [fxFlex]="100 / cols">
                <div>
                    <ng-container *ngFor="let data of objectKeys(image)">
                        <div class="image-container" *ngIf="data === 'image'; else dispData">
                            <div class="image-icons">
                                <i class="ei ei-settings icon" (click)="openEditor(image, i)"></i>
                                <i class="ei ei-crop icon" (click)="openCroppingEditor(image.image, i)"></i>
                            </div>
                            <div class="element-drag-icon-container">
                                <button mat-icon-button class="element-button-drag">
                                    <i class="ei ei-drag element-button-drag-icon element-button-drag"></i>
                                </button>
                            </div>
                            <elias-shared-image-preview
                                [id]="image.image"
                                [aspectRatio]="aspectRatio"
                                (click)="openEditor(image, i)"></elias-shared-image-preview>
                        </div>
                        <ng-template #dispData>
                            <div
                                class="{{ data }}"
                                *ngIf="itemFieldSettings[data] && image[data]"
                                [innerHTML]="image[data] | html"></div>
                            <div
                                class="{{ data }}"
                                *ngIf="itemFieldSettings[data] && !image[data]"
                                [innerHTML]="'<p>&nbsp;</p>'"></div>
                        </ng-template>
                    </ng-container>
                </div>
                <div class="e-image-grid-bottom-line"></div>
            </div>
        </ng-container>
    </div>
</form>
<div class="pe-element-button-container">
    <elias-assetbrowser-button
        #assetBrowserButton
        buttonStyle="flat"
        [text]="'document.image.uploadMultiple'"
        (assetsSelected)="onAssetsSelected($event)"></elias-assetbrowser-button>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AssetStorageType } from '../../../editor/asset-browser/enums/asset-storage-type.enum';

@Pipe({ name: 'assetStorage' })
export class AssetStoragesPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: any, ...args): any {
        if (args[0] === 'title') {
            if (value === 'allAssets') {
                return this.translateService.instant('assetBrowser.assetstorages.all');
            }
            if (value === AssetStorageType.General) {
                return this.translateService.instant('assetBrowser.assetstorages.general');
            }
            if (value === AssetStorageType.PublicationGroup) {
                return this.translateService.instant('assetBrowser.assetstorages.publication-group-title');
            }
            if (value === 'parentShared') {
                return this.translateService.instant('assetBrowser.assetstorages.parentShared');
            }
            if (value === AssetStorageType.Shared) {
                return this.translateService.instant('assetBrowser.assetstorages.shared') + ' - ' + args[1].name;
            }
        }

        if (args[0] === 'button') {
            if (args[1] === 'allAssets') {
                return this.translateService.instant('assetBrowser.upload.general');
            }
            if (args[1] === AssetStorageType.Shared) {
                return this.translateService.instant('assetBrowser.upload.shared');
            }
            if (args[1] === AssetStorageType.General) {
                return this.translateService.instant('assetBrowser.upload.general');
            }
            if (args[1] === AssetStorageType.PublicationGroup) {
                return this.translateService.instant('assetBrowser.upload.publication-group');
            }
        }

        if (args[0] === 'treeName') {
            if (value.type === 'allAssets') {
                return this.translateService.instant('assetBrowser.assetstorages.all');
            }
            if (value.type === AssetStorageType.General) {
                return this.translateService.instant('assetBrowser.assetstorages.general');
            }
            if (value.type === AssetStorageType.PublicationGroup) {
                return this.translateService.instant('assetBrowser.assetstorages.publication-group');
            }
            if (value.id === 'parentShared') {
                return this.translateService.instant('assetBrowser.assetstorages.shared');
            }
            return value.name;
        }

        if (args[0] === 'icons') {
            if (value.id === 'parentShared') {
                return 'link';
            } else if (value.id === 'allAssets') {
                return 'list';
            } else if (value.type === AssetStorageType.General) {
                return 'public';
            } else if (value.type === AssetStorageType.PublicationGroup) {
                return 'folder';
            } else {
                return '';
            }
        }
    }
}

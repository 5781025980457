<div class="e-layout-{{ keyFigureLayout }}" [ngClass]="{ 'align-vertically': alignVertically }">
    <div class="row" *ngIf="!alignVertically">
        <div *ngFor="let keyFigureCol of keyFigures; trackBy: trackByIndex" class="col-{{ cols }}">
            <div *ngFor="let keyFigureItem of keyFigureCol; trackBy: trackByIndex" class="e-key-figure">
                <div class="title" [innerHTML]="keyFigureItem.title"></div>
                <div class="number" [innerHTML]="keyFigureItem.number"></div>
                <div class="unit" [innerHTML]="keyFigureItem.unit"></div>
                <div class="description" [innerHTML]="keyFigureItem.description"></div>
            </div>
        </div>
    </div>

    <div *ngIf="alignVertically">
        <div class="row" *ngFor="let i of elementIndexes; trackBy: trackByIndex">
            <div
                class="column col-{{ cols }}"
                *ngFor="let keyFigureCol of keyFigures; trackBy: trackByIndex; let colIndex = index">
                <div *ngIf="keyFigureCol.length > i" class="e-key-figure">
                    <div class="title" [innerHTML]="keyFigureCol[i]?.title"></div>
                    <div class="number" [innerHTML]="keyFigureCol[i]?.number"></div>
                    <div class="unit" [innerHTML]="keyFigureCol[i]?.unit"></div>
                    <div class="description" [innerHTML]="keyFigureCol[i]?.description"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div [ngClass]="'e-section-type-' + section.type">
    <div
        class="document-container layout-{{ publication.publicationGroup.layout }}"
        [ngClass]="{ inverted: isInverted }"
        [style.background-color]="backgroundColor">
        <elias-shared-loading
            *ngIf="(loaded$ | async) === false; else document"
            [centered]="true"
            [includeText]="true"
            [inverted]="isInverted"
            descriptionContext="document"></elias-shared-loading>
        <ng-template #document>
            <elias-section-buttons></elias-section-buttons>

            <div class="document e-document" [ngClass]="{ 'node-selected': selectedNode !== null }">
                <elias-section-layout [section]="section"></elias-section-layout>
            </div>
        </ng-template>
    </div>
</div>

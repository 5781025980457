import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable, of, Subscription } from 'rxjs';
import { DropzoneConfigInterface, DropzoneDirective } from 'ngx-dropzone-wrapper';
import { Asset } from '../../../../modules/shared/models/asset.model';
import { AssetStorage } from '../../models/asset-storage.model';
import { AssetStoragesQuery } from '../../state/asset-storages/asset-storages.query';
import { AssetCollectionsQuery } from '../../../../modules/shared/state/asset-collections/asset-collections.query';
import { AssetCollectionsService } from '../../../../modules/shared/services/asset-collections.service';
import { AspectRatiosQuery } from '../../../../modules/shared/state/aspect-ratios/aspect-ratios.query';
import { AssetsService } from '../../../../modules/shared/services/assets.service';
import { RequestDto } from '../../../editor/models/dto/request-dto.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { delay, filter, first, switchMap, tap, throttleTime } from 'rxjs/operators';
import { DomainService } from '../../../../modules/core/services/domain.service';
import { UiService } from '../../../../modules/core/services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { AssetStoragesService } from '../../services/asset-storages.service';
import { AspectRatiosService } from '../../../../modules/shared/services/aspect-ratios.service';
import { AssetSort } from '../../enums/asset-sort.enum';
import { FlashMessageService } from '../../../../modules/core/services/flash-message.service';
import { ITreeOptions, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { slideInAnimation } from '../../animation';
import { AssetBrowserHelperService } from '../../services/asset-browser-helper.service';
import { PublicationGroupsQuery } from '../../../../publication-group/state/publication-groups/publication-groups.query';
import { PublicationGroup } from '../../../../publication-group/models/publication-group.model';
import { LocalStorageKeys } from '../../../../modules/shared/enums/local-storage-keys.enum';
import { AssetStorageType } from '../../enums/asset-storage-type.enum';
import { PublicationsQuery } from '../../../../publication/state/publications/publications.query';
import { UrlParamsHandlerInterface } from '../../../../modules/links/interfaces/url-params-handler.interface';
import { PageEvent } from '@angular/material/paginator';
import { QueryParametersBuilder } from '../../../../modules/core/builders/query-parameters.builder';
import { SortingDirection } from '../../../../modules/shared/enums/sorting-direction.enum';
import { UrlParamsService } from '../../../../modules/links/services/url-params.service';
import { KeyValueOption } from '../../../../modules/shared/models/key-value-option.model';
import { ImageTypes } from '../../models/image-types.model';
import { SpreadsheetTypes } from '../../models/spreadsheet-types.model';
import { AssetBrowserService } from '../../services/asset-browser.service';
import { AssetBrowserQuery } from '../../state/asset-browser/asset-browser.query';
import { AssetBrowserStore } from '../../state/asset-browser/asset-browser.store';
import { AssetsStore } from '../../../../modules/shared/state/assets/assets.store';
import { ImagesStore } from '../../../editor-container/state/images/images.store';
import { AssetCroppingResultDto } from '../../../image-cropping/types';

@Component({
    selector: 'elias-assetbrowser',
    styleUrls: ['./asset-browser.component.scss'],
    templateUrl: './asset-browser.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [slideInAnimation],
})
export class AssetBrowserComponent implements OnInit, OnDestroy, AfterViewInit, UrlParamsHandlerInterface {
    @Input() allowedTypes: string[] = [];
    @Input() assetsPerPage: number = 36;
    @Input() fixedHeight: boolean = true;
    @Input() single: boolean = false;
    @Input() type: string = '';

    assetManagerAssets$: Observable<Asset[]> = this.assetBrowserQuery.selectAll();

    assetFilters$ = this.assetBrowserQuery.selectFilters$;
    assetSorting$ = this.assetBrowserQuery.selectSort$;
    assetViewOption$ = this.assetBrowserQuery.selectViewOption$;

    collectionOptions: KeyValueOption[] = [];
    fileTypeOptions: KeyValueOption[] = [
        {
            key: 'images',
            value: this.translateService.instant('assetBrowser.file.images'),
        },
        {
            key: 'spreadsheets',
            value: this.translateService.instant('assetBrowser.file.spreadsheet'),
        },
    ];
    sortOptions: KeyValueOption[] = [];
    filteredAssets$: Observable<any>;

    assetStorage$: Observable<AssetStorage[]> = this.assetStoragesQuery.selectAll();
    assetStorages: AssetStorage[] = [];

    selectedAssets: Asset[] = [];
    subscription: Subscription;

    searchTerm: string | null = '';
    stickySearchTerm: string | null;
    sort = 'updatedAt';
    sortDirection: SortingDirection = SortingDirection.Desc;
    page: number;
    selectedCollection: string;
    selectedStorage: AssetStorage | null = null;
    selectedStorageType: string | null = AssetStorageType.PublicationGroup;
    dropzoneConfig: DropzoneConfigInterface = {
        url: this.domainService.apiBaseUrl + '/assets',
        maxFilesize: 50,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem(LocalStorageKeys.Token),
            'Cache-Control': null,
            'X-Requested-With': null,
        },
        acceptedFiles: ImageTypes.toString() + ',.xls,.xlsx',
        previewsContainer: '#dropzonePreviewContainer',
        clickable: '.upload-button',
        params: {},
    };

    initialLoad = true;
    showAllState = true;
    loaded$ = new BehaviorSubject<boolean>(false);

    @ViewChild('filterName') filterName: ElementRef;
    @ViewChild('stickyFilterName') stickyFilterName: ElementRef;
    @ViewChild(TreeComponent) private treeComponent: TreeComponent;

    active = false;
    boundaryAllowedTypes: string[] = [];
    public totalDataLength$ = this.assetBrowserQuery.select('totalDataLength');
    toggleView = false;
    treeOptions: ITreeOptions;
    imageAllowedTypes: string[] = ImageTypes;
    assetViewOption: number;
    uploadInProgress;
    targetDropzone;
    allStorages$;
    storageTypesNotAllowed: string[] = ['allAssets', 'parentShared'];
    publicationGroupId: string;
    publicationId: string;

    @ViewChild(DropzoneDirective, { static: true }) directiveRef?: DropzoneDirective;

    constructor(
        @Optional() public activeModal: MatDialogRef<AssetBrowserComponent>,
        private aspectRatiosQuery: AspectRatiosQuery,
        private aspectRatiosService: AspectRatiosService,
        private assetBrowserHelperService: AssetBrowserHelperService,
        private assetCollectionsQuery: AssetCollectionsQuery,
        private assetCollectionsService: AssetCollectionsService,
        private assetBrowserQuery: AssetBrowserQuery,
        private assetBrowserService: AssetBrowserService,
        private assetBrowserStore: AssetBrowserStore,
        private assetsStore: AssetsStore,
        private imagesStore: ImagesStore,
        private assetsService: AssetsService,
        private assetStoragesQuery: AssetStoragesQuery,
        private assetStoragesService: AssetStoragesService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        @Optional() public dialog: MatDialog,
        private domainService: DomainService,
        private flashMessageService: FlashMessageService,
        private publicationGroupQuery: PublicationGroupsQuery,
        private publicationsQuery: PublicationsQuery,
        private translateService: TranslateService,
        private uiService: UiService,
        private urlParamsService: UrlParamsService
    ) {}

    ngOnInit() {
        this.filteredAssets$ = this.assetManagerAssets$;
        this.publicationGroupId = this.publicationGroupQuery.getActiveId() as string;
        this.publicationId = this.publicationsQuery.getActiveId() as string;
        this.loadData();
        this.uiService.setToolbarState('editor');

        this.allStorages$ = this.assetStoragesQuery.selectAssetStorageTree();
        this.treeOptions = this.getAssetTreeOptions();
        this.data = this.data ? this.data : {};
        this.single = this.data.single ? this.data.single : this.single;
        this.allowedTypes = this.data.allowedTypes ? this.data.allowedTypes : this.allowedTypes;
        this.type = this.data.type ? this.data.type : '';

        if (this.data.preSelectedAsset) {
            this.selectedAssets = [this.data.preSelectedAsset];
        }

        this.dropzoneConfig.params = { publication: this.publicationId };
        if (this.selectedStorage) {
            this.dropzoneConfig.clickable = '.upload-button';
        }
        this.boundaryAllowedTypes = this.allowedTypes;

        this.assetCollectionsQuery.selectAll().subscribe((collections) => {
            this.collectionOptions = collections.map((collection) => {
                return { key: collection.id, value: collection.name };
            });
        });

        this.assetViewOption$.subscribe((value) => (this.assetViewOption = value));

        const selectAssetStorage$ = this.assetStorage$.pipe(
            tap((assetStorages) => {
                if (assetStorages.length > 0) {
                    this.assetStorages = assetStorages;
                    if (localStorage.getItem(LocalStorageKeys.StorageFrom)) {
                        this.selectedStorage = assetStorages.filter(
                            (value) => value.type === localStorage.getItem(LocalStorageKeys.StorageFrom)
                        )[0];
                        this.selectStorage(this.selectedStorage, this.selectedStorage.id, this.selectedStorage.type);
                    } else if (this.data.preSelectedAsset) {
                        const preselectedStorage = this.data.preSelectedAsset.storage as AssetStorage;
                        this.selectStorage(preselectedStorage, preselectedStorage.id, preselectedStorage.type);
                    } else {
                        this.selectedStorage = assetStorages.filter(
                            (value) => value.type === AssetStorageType.PublicationGroup
                        )[0];
                        this.updateDropzoneConfig(this.selectedStorage.type, this.selectedStorage.id);
                    }
                    this.getAssetFiles();
                }
            })
        );

        this.subscription = this.loaded$
            .pipe(
                delay(50),
                filter(Boolean),
                tap(() => {
                    if (this.treeComponent && this.treeComponent.treeModel && this.treeComponent.treeModel.nodes) {
                        this.treeComponent.treeModel.nodes.forEach((treeNode) => {
                            if (localStorage.getItem(LocalStorageKeys.StorageFrom)) {
                                if (treeNode.type === localStorage.getItem(LocalStorageKeys.StorageFrom)) {
                                    this.treeComponent.treeModel.getNodeById(treeNode.id)?.setActiveAndVisible(true);
                                }
                            } else {
                                if (this.data.preSelectedAsset && this.data.preSelectedAsset.storage) {
                                    this.treeComponent.treeModel
                                        .getNodeById(this.data.preSelectedAsset.storage.id)
                                        ?.setActiveAndVisible(true);
                                } else if (treeNode.type === AssetStorageType.PublicationGroup) {
                                    this.treeComponent.treeModel.getNodeById(treeNode.id)?.setActiveAndVisible(true);
                                }
                            }
                        });
                    }

                    if (localStorage.getItem(LocalStorageKeys.StorageFrom) === AssetStorageType.PublicationGroup) {
                        const publicationGroupId = this.publicationGroupQuery.getActiveId();
                        const assetStorage = this.assetStoragesQuery.getAll({
                            filterBy: (entity) => entity.publicationGroupId === publicationGroupId,
                        })[0];
                        this.updateDropzoneConfig(assetStorage.type, assetStorage.id);
                    }
                }),
                switchMap(() => selectAssetStorage$),
                switchMap(() => this.handleUrlParams())
            )
            .subscribe();

        this.sortOptions = Object.keys(AssetSort).map((assetSort) => {
            return {
                key: assetSort,
                value: this.translateService.instant('assetBrowser.sort.' + assetSort),
            };
        });

        if (this.fixedHeight) {
            this.onChangeViewOption(0);
        }
    }

    ngAfterViewInit() {
        if (!this.fixedHeight) {
            const scroll = fromEvent(document.querySelector('.content-wrapper'), 'scroll');
            scroll.pipe(throttleTime(25)).subscribe(() => this.stickToTop());
        }
    }

    handleUrlParams(): Observable<any> {
        return this.urlParamsService.getParamFromURL('asset').pipe(
            tap(({ value }) => {
                const asset = this.assetBrowserQuery.getEntity(value);
                const { storage } = asset;

                this.selectStorage(storage, storage.id, storage.type);
                if (
                    this.treeComponent &&
                    this.treeComponent.treeModel &&
                    this.treeComponent.treeModel.getNodeById(storage.id)
                ) {
                    this.treeComponent.treeModel.getNodeById(storage.id).setActiveAndVisible(false);
                    this.getAssetFiles();
                }
            })
        );
    }

    stickToTop() {
        if (!this.fixedHeight) {
            this.assetBrowserHelperService.stickToTop(this.stickySearchTerm);
        }
    }

    applyNewStickyCondition(value) {
        if (!this.fixedHeight) {
            this.assetBrowserHelperService.applyNewStickyCondition(value);
        }
    }

    loadData() {
        const observables = [];

        if (!this.assetCollectionsQuery.getValue().loaded) {
            observables.push(this.assetCollectionsService.getAssetCollections());
        }
        if (!this.aspectRatiosQuery.getValue().loaded) {
            observables.push(this.aspectRatiosService.getAspectRatios());
        }
        if (!this.assetStoragesQuery.getValue().loaded) {
            if (this.publicationGroupId) {
                const payload = new RequestDto({ pubGroupId: this.publicationGroupId }, {}, {});
                observables.push(this.assetStoragesService.getAssetStorages(payload));
            }
        }

        if (observables.length > 0) {
            this.loaded$.next(false);
            forkJoin(observables)
                .pipe(first())
                .subscribe((results) => {
                    this.loaded$.next(true);
                });
        } else {
            this.loaded$.next(true);
        }
    }

    close() {
        this.activeModal.close(false);
    }

    returnAssets() {
        this.activeModal.close(this.selectedAssets);
    }

    toggleCollectionSelection(id: string) {
        this.selectedCollection = id;
        this.page = 1;
        this.getAssetFiles();
    }

    selectStorage(storage: AssetStorage | null, id, type) {
        if ((storage === undefined || storage === null) && id === 'allAssets') {
            this.selectedStorage = null;
            this.selectedStorageType = 'allAssets';
            this.page = 1;
            this.assetStorages = this.assetStoragesQuery.getAll();
        } else if ((storage === undefined || storage === null) && id === 'parentShared') {
            this.assetStorages = this.assetStorages.filter((item) => item.type === AssetStorageType.Shared);
            this.selectedStorage = null;
            this.selectedStorageType = 'parentShared';
            this.page = 1;
        } else {
            this.selectedStorage = storage;
            this.selectedStorageType = storage.type;
            this.page = 1;
        }

        this.updateDropzoneConfig(id === 'allAssets' ? AssetStorageType.General : type, this.selectedStorage?.id);
        this.getAssetFiles();
    }

    private updateDropzoneConfig(type: AssetStorageType, assetStorageId: string | undefined = undefined): void {
        if (type === AssetStorageType.General) {
            this.dropzoneConfig.url = this.domainService.apiBaseUrl + '/assets';
            this.dropzoneConfig.params = {
                publication: this.publicationId,
            };
        } else if (type === AssetStorageType.Shared) {
            this.dropzoneConfig.url = this.domainService.apiBaseUrl + '/assets/shared';
            this.dropzoneConfig.params = {
                assetStorage: assetStorageId,
                publication: this.publicationId,
            };
        } else if (type === AssetStorageType.PublicationGroup) {
            this.dropzoneConfig.url = this.domainService.apiBaseUrl + '/assets/publication-only';
            this.dropzoneConfig.params = {
                assetStorage: assetStorageId,
                publication: this.publicationId,
            };
        }
    }

    onAssetClick(asset: Asset) {
        const index = this.selectedAssets.map((a) => a.id).indexOf(asset.id);

        if (index === -1) {
            if (this.single) {
                this.selectedAssets = [asset];
            } else {
                this.selectedAssets.push(asset);
            }
        } else {
            this.selectedAssets.splice(index, 1);

            // show all when last asset is removed from selected assets and only selected assets are shown
            if (this.selectedAssets.length === 0 && !this.showAllState) {
                this.showAll();
            }
        }
    }

    onClearSelection(reload = false) {
        this.selectedAssets = [];

        if (reload) {
            this.getAssetFiles();
        }
    }

    onPageChange(page: PageEvent) {
        this.page = page.pageIndex + 1;
        this.getAssetFiles();
    }

    getAssetFiles() {
        const queryParametersBuilder = new QueryParametersBuilder();

        if (this.selectedStorage) {
            queryParametersBuilder.addFilter('storage', this.selectedStorage.id);
        } else {
            const assetStorageIds = this.assetStorages.map((assetStorage) => assetStorage.id);
            queryParametersBuilder.addFilter('storage', assetStorageIds);
        }

        queryParametersBuilder.addFilter('assetCollections', this.selectedCollection);
        queryParametersBuilder.addFilter('mimeType', this.allowedTypes);

        queryParametersBuilder.addSorting(this.sort, this.sortDirection);
        queryParametersBuilder.addSearch((this.searchTerm || this.stickySearchTerm) ?? undefined);
        queryParametersBuilder.addPage(this.page, this.assetsPerPage);

        const options = queryParametersBuilder.getQueryOptions();

        const publicationGroupId = (this.publicationGroupQuery.getActive() as PublicationGroup).id;
        this.assetBrowserService.getAssetManagerAssets(publicationGroupId, options).subscribe((assetManagerAssets) => {
            if (assetManagerAssets['data'].length > 0) {
                const assetIds = assetManagerAssets['data']
                    .filter((asset: Asset) => {
                        return this.assetBrowserQuery.hasEntity(asset.id) && this.assetsService.isImage(asset);
                    })
                    .map((asset: Asset) => asset.id);
                this.assetBrowserService.getAssetBrowserThumbnails(assetIds).subscribe();
            }
        });
        this.initialLoad = false;
    }

    onAssetClonedAndCropped(result: AssetCroppingResultDto): void {
        this.getAssetFiles();
    }

    onFileReplaced(asset: Asset) {
        this.assetBrowserStore.update(asset.id, { thumbnail: undefined });

        // Force editor to refresh
        this.assetsStore.remove(asset.id);
        this.imagesStore.remove(asset.id);

        const payload = new RequestDto({ assetId: asset.id }, {}, {});
        this.assetBrowserService.loadSingleAsset(payload).subscribe();
    }

    onUploadComplete(event) {
        this.getAssetFiles();
    }

    onErrorUpload(event) {
        this.flashMessageService.showError(event);
    }

    onUploadStart(event) {}

    showAll() {
        this.filteredAssets$ = this.assetBrowserQuery.selectAll();
        this.getAssetFiles();
        this.showAllState = true;
    }

    getCount() {
        return { count: this.selectedAssets.length };
    }

    public onChangeFileType(value: string | null): void {
        this.allowedTypes = this.boundaryAllowedTypes;
        this.assetBrowserService.setFilter('fileType', value);

        this.determineAllowedTypesBasedOnFilter(value);

        if (
            (this.boundaryAllowedTypes.length === 0 && this.allowedTypes.length === 0) ||
            this.allowedTypes.length > 0
        ) {
            this.getAssetFiles();
            this.filteredAssets$ = this.assetManagerAssets$;
        } else {
            this.filteredAssets$ = of([]);
        }
    }

    public onChangeCollection(value: string): void {
        this.assetBrowserService.setFilter('collection', value);
        this.toggleCollectionSelection(value);
    }

    public onChangeSortingValue(value: string): void {
        this.assetBrowserService.setSorting({ key: value, direction: 'asc' });
        this.sort = value;
        this.sortDirection = SortingDirection.Asc;
        this.getAssetFiles();
    }

    ngOnDestroy(): void {
        if (!this.fixedHeight) {
            this.uiService.setToolbarState('overview');
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        localStorage.removeItem(LocalStorageKeys.StorageFrom);
    }

    determineAllowedTypesBasedOnFilter(value: string | null): void {
        if (value === 'images') {
            this.allowedTypes = this.imageAllowedTypes;
            if (this.boundaryAllowedTypes.length > 0) {
                this.allowedTypes = this.imageAllowedTypes.filter((val) => this.boundaryAllowedTypes.includes(val));
            }
        }

        if (value === 'spreadsheets') {
            this.allowedTypes = SpreadsheetTypes;
            if (this.boundaryAllowedTypes.length > 0) {
                this.allowedTypes.filter((val) => this.boundaryAllowedTypes.includes(val));
            }
        }
    }

    applyFilter(filterValue: string | null): void {
        this.searchTerm = filterValue;

        if (!filterValue) {
            this.filterName.nativeElement.value = '';
            this.filteredAssets$ = this.assetManagerAssets$;
        }

        this.getAssetFiles();
    }

    applyStickyFilter(filterValue: string | null): void {
        this.stickySearchTerm = filterValue;

        if (!filterValue) {
            this.stickyFilterName.nativeElement.value = '';
            this.filteredAssets$ = this.assetManagerAssets$;
        }

        this.applyNewStickyCondition(filterValue);
        this.getAssetFiles();
    }

    public onChangeViewOption(viewOption) {
        this.assetBrowserService.changeViewOption(viewOption);
    }

    toggle() {
        this.toggleView = !this.toggleView;
    }

    public getAssetTreeOptions(): ITreeOptions {
        return {
            displayField: 'title',
            actionMapping: {
                mouse: {
                    click: (tree: TreeModel, node: TreeNode, $event) => {
                        this.checkForAssetActivation(tree, node, $event);
                    },
                },
                keys: {
                    13: (tree: TreeModel, node: TreeNode, $event) => {
                        this.checkForAssetActivation(tree, node, $event);
                    },
                    32: (tree: TreeModel, node: TreeNode, $event) => {
                        this.checkForAssetActivation(tree, node, $event);
                    },
                },
            },
            allowDrag: (node) => {
                return false;
            },
            allowDrop: (node, { parent, index }) => {
                return false;
            },
        };
    }

    checkForAssetActivation(tree: TreeModel, node: TreeNode, $event) {
        this.assetBrowserService.setFilter('storage', node.data.key);
        tree.getActiveNodes().forEach((node, index) => {
            tree.setActiveNode(node, false);
        });
        TREE_ACTIONS.FOCUS(tree, node, $event);
        const storage = this.assetStoragesQuery.getEntity(node.data.id);
        this.selectStorage(storage, node.data.id, node.data.type);
    }

    onUploadInProgress(value) {
        this.uploadInProgress = value;
    }

    onDragEnter(event) {
        if (this.assetBrowserHelperService.isFile(event)) {
            this.targetDropzone = event.target;
            this.assetBrowserHelperService.onDragEnter();
        }
    }

    onAssetDropped(event) {
        event.preventDefault();
        this.assetBrowserHelperService.onDrop();
    }

    onDragOver(event) {
        event.preventDefault();
    }

    onDragLeave(event) {
        event.preventDefault();
        if (event.target === this.targetDropzone || event.target === document) {
            this.assetBrowserHelperService.onDragLeave();
        }
    }

    isEmpty(type) {
        return !type || type.length == 0;
    }
}

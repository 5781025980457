import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { Breadcrumb } from '@sentry/angular';
import { SectionsQuery } from '../state/sections/sections.query';

@Injectable()
export class EditorBreadcrumbResolver implements Resolve<any> {
    constructor(private sectionsQuery: SectionsQuery) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.sectionsQuery.selectAll().pipe(
            first((sections) => sections.length > 1),
            switchMap((sections) => {
                const firstSectionId = sections[1].id;
                const breadcrumb = {
                    title: 'core.toolbar.editor',
                    url: `sections/${firstSectionId}`,
                } as Breadcrumb;

                return of(breadcrumb);
            })
        );
    }
}

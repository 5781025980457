import { Injectable } from '@angular/core';
import { catchError, share, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import * as _ from 'lodash';
import { DomainService } from '../../../modules/core/services/domain.service';
import { ImagesStore } from '../state/images/images.store';
import { ImagesQuery } from '../state/images/images.query';
import { RequestDto } from '../../editor/models/dto/request-dto.model';
import { Image } from '../models/image.model';
import { ImageDto } from '../../../modules/shared/models/image-dto.model';

@Injectable()
export class ImagesService {
    private imageObservables: { [k: string]: Observable<ImageDto> } = {};

    constructor(
        private domainService: DomainService,
        private http: HttpClient,
        private imagesStore: ImagesStore,
        private imagesQuery: ImagesQuery
    ) {}

    loadSingleImage(payload: RequestDto): Observable<ImageDto> {
        const imageId = payload.getParam('assetId');
        const aspectRatioId = payload.getParam('aspectRatio');

        /* This is needed to avoid multiple calls for the same image and aspect ratio.
           Observables are saved, and new ones are created only when there is no observable 
           for given image id and aspect ratio. */
        const compositeId = imageId + aspectRatioId;
        if (compositeId in this.imageObservables) {
            return this.imageObservables[compositeId];
        }

        // Set loading
        this.imagesStore.setLoading(true);

        const observable = this.http
            .get<ImageDto>(`${this.domainService.apiBaseUrl}/images/${imageId}/${aspectRatioId}`)
            .pipe(
                tap((image) => {
                    const content = URL.createObjectURL(this.b64toBlob(image.content));

                    if (this.imagesQuery.hasEntity(image.id)) {
                        const existingImage = this.setImageAspectRatio(image, content);
                        this.imagesStore.replace(image.id, existingImage);
                    } else {
                        const newImage: Image = {
                            id: image.id,
                            aspectRatios: { [image.aspectRatio]: { content } },
                            type: image.type,
                        };
                        this.imagesStore.add(newImage);
                    }

                    // Set not loading
                    this.imagesStore.setLoading(false);
                    delete this.imageObservables[compositeId];
                }),
                catchError((error: any) => {
                    return throwError(error);
                }),
                share()
            );

        this.imageObservables[compositeId] = observable;

        return observable;
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 1024): Blob {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    private setImageAspectRatio(image, content: string): Image {
        const returnImage = _.cloneDeep(this.imagesQuery.getEntity(image.id));
        if (!returnImage.aspectRatios) {
            returnImage.aspectRatios = {};
        }
        returnImage.aspectRatios[image.aspectRatio] = { content };

        return returnImage;
    }
}

<div class="btn-container">
    <i
        data-cy="view-option-card"
        class="ei ei-card"
        [ngClass]="{ selected: viewOption === 0 }"
        (click)="changeViewOption(0)"></i>
    <i
        data-cy="view-option-list"
        class="ei ei-list"
        [ngClass]="{ selected: viewOption === 2 }"
        (click)="changeViewOption(2)"></i>
</div>

import { ChannelsStore } from '../state/channels/channels.store';
import { Injectable } from '@angular/core';
import { ChannelsQuery } from '../state/channels/channels.query';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DomainService } from '../../core/services/domain.service';
import { Publication } from '../../../publication/models/publication.model';
import { PublicationsQuery } from '../../../publication/state/publications/publications.query';
import { Channel } from '../models/channel.model';
import { RequestDto } from '../../../editor/editor/models/dto/request-dto.model';
import { NewChannelDto } from '../../../settings/models/new-channel-dto.model';

@Injectable({
    providedIn: 'root',
})
export class ChannelsService {
    baseUrl: string = this.domainService.apiBaseUrl + '/channels';

    constructor(
        private channelsStore: ChannelsStore,
        private channelQuery: ChannelsQuery,
        private domainService: DomainService,
        private publicationsQuery: PublicationsQuery,
        private http: HttpClient
    ) {}

    private handleError(error: HttpErrorResponse) {
        switch (error.status) {
            case 400:
                return throwError(error.message);
        }
    }

    getChannelsForPublication(id: string): Observable<Channel[]> {
        this.channelsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/publications/${id}/channels`;

        return this.http.get<Channel[]>(url).pipe(
            tap((channels) => {
                this.channelsStore.set(channels);
                this.channelsStore.setLoading(false);
                this.channelsStore.update({ loaded: true });
            }),

            catchError((error: any) => throwError(error))
        );
    }

    getChannels(): Observable<Channel[]> {
        this.channelsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/channels`;
        return this.http.get<Channel[]>(url).pipe(
            tap((channels) => {
                this.channelsStore.set(channels);
                this.channelsStore.setLoading(false);
                this.channelsStore.update({ loaded: true });
            }),

            catchError((error: any) => throwError(error))
        );
    }

    createChannel(channel: NewChannelDto): Observable<any> {
        this.channelsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/channels`;

        return this.http.post<Channel>(url, channel).pipe(
            tap((channel) => {
                this.channelsStore.add(channel);
                this.channelsStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updateChannel(channel: Channel, data = null) {
        this.channelsStore.setLoading(true);
        data = data ? data : channel;
        const url = `${this.domainService.apiBaseUrl}/channels/${channel.id}`;
        const payload: RequestDto = new RequestDto(null, null, data);
        return this.http.patch<Channel[]>(url, payload.body).pipe(
            tap((channel) => {
                this.channelsStore.set(channel);
                this.channelsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    updateActiveChannel(channel: Channel, data = null) {
        this.channelsStore.setLoading(true);
        data = data ? data : channel;
        const url = `${this.domainService.apiBaseUrl}/channels/${channel.id}/enable`;
        const payload: RequestDto = new RequestDto(null, null, data);
        return this.http.patch<Channel>(url, payload.body).pipe(
            tap((channel) => {
                this.channelsStore.update(channel.id, channel);
                this.channelsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }
}

<form
    [formGroup]="loginForm"
    *ngIf="currentStep === 'login'"
    class="form-row"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true">
    <h1>{{ 'login.signin' | translate }}</h1>
    <p>{{ 'login.description' | translate }}</p>
    <div class="spacer"></div>

    <div class="form-row" *ngIf="!isSaving">
        <mat-form-field class="login-control">
            <mat-label>{{ 'login.username' | translate }}</mat-label>
            <input
                matInput
                #username
                placeholder="{{ 'login.username' | translate }}"
                type="text"
                formControlName="username"
                required
                data-cy="usernameInput" />
            <mat-error *ngIf="loginForm.controls.username.hasError('required')">
                {{ 'login.usernameerror' | translate }}
            </mat-error>
            <mat-error
                *ngIf="
                    loginForm.controls.username.hasError('maxlength') &&
                    !loginForm.controls.username.hasError('required')
                ">
                The username cannot be longer than 255 characters
            </mat-error>
        </mat-form-field>
    </div>

    <div class="form-row" *ngIf="!isSaving">
        <mat-form-field class="login-control">
            <mat-label>{{ 'login.password' | translate }}</mat-label>
            <input
                matInput
                #password
                placeholder="{{ 'login.password' | translate }}"
                formControlName="password"
                [type]="isVisible ? 'password' : 'text'"
                required
                data-cy="passwordInput" />
            <ng-container *ngIf="isVisible">
                <i class="ei ei-visibility-off" (click)="isVisible = !isVisible"></i>
            </ng-container>
            <ng-container *ngIf="!isVisible">
                <i class="ei ei-visibility" (click)="isVisible = !isVisible"></i>
            </ng-container>
            <mat-error *ngIf="loginForm.controls.password.hasError('required')">
                {{ 'login.passworderror' | translate }}
            </mat-error>
            <mat-error
                *ngIf="
                    loginForm.controls.password.hasError('maxlength') &&
                    !loginForm.controls.password.hasError('required')
                ">
                The password cannot be longer than 255 characters
            </mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="inMaintenance && !isSaving" class="warning-message">
        <i class="ei ei-error-full"></i>
        <span class="message">{{ 'login.maintenanceWhitelisted' | translate }}</span>
    </div>

    <div *ngIf="(showMaintenanceScheduledWarning$ | async) && !isSaving" class="warning-message">
        <i class="ei ei-error-full"></i>
        <span class="message">{{ maintenanceScheduledWarning$ | async }}</span>
    </div>

    <div *ngIf="loginErrorMessage && !isSaving" class="error-message">
        <i class="ei ei-error-full"></i>
        <span class="message">{{ loginErrorMessage }}</span>
    </div>

    <div class="button" *ngIf="!isSaving">
        <button mat-stroked-button *ngIf="loginForm.invalid">
            {{ 'login.login' | translate }}
        </button>
        <button
            mat-flat-button
            color="accent"
            *ngIf="loginForm.valid || isSaving"
            (click)="login()"
            data-cy="loginButton">
            {{ 'login.login' | translate }}
        </button>
    </div>
    <elias-shared-loading *ngIf="isSaving"></elias-shared-loading>
</form>

<form [formGroup]="twoFactorForm" *ngIf="currentStep === 'twoFactor'" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <h1>{{ 'login.signin' | translate }}</h1>
    <p>{{ 'login.securitycodetext' | translate }}</p>
    <div class="spacer"></div>

    <div class="form-row security-code">
        <mat-form-field class="login-control">
            <mat-label>{{ 'login.securitycode' | translate }} </mat-label>
            <input
                matInput
                #code
                placeholder="{{ 'login.securitycode' | translate }}"
                type="password"
                formControlName="code"
                [type]="isVisible ? 'password' : 'text'"
                autofocus
                data-cy="authCodeInput" />
            <ng-container *ngIf="isVisible">
                <i class="ei ei-visibility-off" (click)="isVisible = !isVisible"></i>
            </ng-container>
            <ng-container *ngIf="!isVisible">
                <i class="ei ei-visibility" (click)="isVisible = !isVisible"></i>
            </ng-container>
            <mat-error *ngIf="twoFactorForm.controls.code.hasError('required')">
                {{ 'login.codeerror' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="secureLoginErrorMessage" class="error-message">
        <i class="ei ei-error-full"></i>
        <span class="message">{{ secureLoginErrorMessage }}</span>
    </div>

    <div *ngIf="securityLoginMessageSentAgain" class="warning-message">
        <i class="ei ei-alert"></i>
        <span class="message">{{ securityLoginMessageSentAgain }}</span>
    </div>

    <div class="button-security-code">
        <button mat-stroked-button *ngIf="twoFactorForm.invalid">
            {{ 'login.login' | translate }}
        </button>
        <button
            mat-flat-button
            color="accent"
            *ngIf="twoFactorForm.valid"
            (click)="secureLogin()"
            data-cy="loginButton">
            {{ 'login.login' | translate }}
        </button>
    </div>

    <div class="spacer"></div>

    <div>
        {{ 'login.securityCodeNotReceived' | translate }}
        <span (click)="startAgain()" class="link">{{ 'login.requestNewSecurityCode' | translate }}</span
        >.
    </div>
</form>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CroppingEditorComponent } from './cropping-editor/cropping-editor.component';
import { SharedModule } from '../../modules/shared/shared.module';
import { CroppingAssetConfirmationComponent } from './cropping-asset-confirmation/cropping-asset-confirmation.component';

@NgModule({
    declarations: [CroppingEditorComponent, CroppingAssetConfirmationComponent],
    imports: [CommonModule, SharedModule],
    exports: [],
})
export class ImageCroppingModule {}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Asset } from '../../../../modules/shared/models/asset.model';
import { AssetBrowserComponent } from '../asset-browser/asset-browser.component';
import { MatDialog } from '@angular/material/dialog';
import { ImageTypes } from '../asset-browser/../../models/image-types.model';

@Component({
    selector: 'elias-assetbrowser-button',
    styleUrls: ['asset-browser-button.component.scss'],
    templateUrl: './asset-browser-button.component.html',
})
export class AssetBrowserButtonComponent {
    @Input() allowedTypes: string[] = ImageTypes;
    @Input() block: boolean = false;
    @Input() buttonStyle: string = 'stroked';
    @Input() selectedAsset?: Asset;
    @Input() single: boolean = false;
    @Input() text: string = '';
    @Input() type: string = 'images';

    @Output() assetsSelected = new EventEmitter<Asset[]>();

    constructor(public dialog: MatDialog) {}

    openAssetManager() {
        const modalRef = this.dialog.open(AssetBrowserComponent, {
            width: '1574px',
            height: '798px',
            data: {
                single: this.single,
                allowedTypes: this.allowedTypes,
                fullscreen: false,
                type: this.type,
                preSelectedAsset: this.selectedAsset,
            },
            autoFocus: false,
        });

        modalRef.afterClosed().subscribe((result) => {
            if (result) {
                this.assetsSelected.emit(result);
            }
        });
    }
}

<div class="modal-container delete-container" [ngClass]="{ notext: !requireExplicitConfirmation }">
    <h1 mat-dialog-title>{{ 'modal.delete.' + type + '.title' | translate }}</h1>
    <form [formGroup]="deleteFormGroup">
        <div mat-dialog-content class="modal-content">
            <ng-container *ngIf="requireExplicitConfirmation">
                <p>{{ 'modal.delete.' + type + '.message' | translate }}</p>
            </ng-container>
            <ng-container *ngIf="!requireExplicitConfirmation">
                <p>{{ 'modal.delete.' + type + '.notvisiblemessage' | translate }}</p>
            </ng-container>
            <ng-container *ngIf="requireExplicitConfirmation">
                <ng-container *ngIf="type === 'publication'">
                    <mat-form-field class="modal-input">
                        <mat-label>{{ 'modal.delete.publication.delete' | translate }}</mat-label>
                        <input
                            data-cy="input-delete-confirmation"
                            matInput
                            placeholder="{{ 'modal.delete.publication.delete' | translate }}"
                            formControlName="deleteInput" />
                        <mat-error *ngIf="deleteFormGroup.controls.deleteInput.hasError('required')">
                            {{ 'modal.delete.' + type + '.error' | translate }}
                        </mat-error>
                        <mat-hint>{{ 'modal.delete.' + type + '.hint' | translate }}</mat-hint>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="type !== 'publication'">
                    <mat-form-field class="modal-input">
                        <mat-label>{{ 'modal.delete.' + type + '.title' | translate }}</mat-label>
                        <input
                            data-cy="input-delete-confirmation"
                            matInput
                            placeholder="{{ 'modal.delete.' + type + '.title' | translate }}"
                            formControlName="deleteInput" />
                        <mat-error *ngIf="deleteFormGroup.controls.deleteInput.hasError('required')">
                            {{ 'modal.delete.' + type + '.error' | translate }}
                        </mat-error>
                        <mat-hint>{{ 'modal.delete.' + type + '.hint' | translate }}</mat-hint>
                    </mat-form-field>
                </ng-container>
            </ng-container>
        </div>
        <div mat-dialog-actions class="modal-action-buttons">
            <button mat-button mat-dialog-close (click)="onCancel()" class="modal-cancel-button">
                {{ 'actions.cancel' | translate }}
            </button>
            <button
                data-cy="remove-btn"
                mat-stroked-button
                color="warn"
                (click)="onConfirm()"
                class="modal-save-button">
                {{ 'actions.delete' | translate }}
            </button>
        </div>
    </form>
</div>

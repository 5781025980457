import { Injectable } from '@angular/core';

@Injectable()
export class AssetBrowserHelperService {
    constructor() {}

    public isFile(evt: DragEvent): boolean {
        let dt = evt.dataTransfer;

        if (!dt) {
            return false;
        }

        for (let i = 0; i < dt.types.length; i++) {
            if (dt.types[i] === 'Files') {
                return true;
            }
        }
        return false;
    }

    public onDragEnter(): void {
        let dropzone = document.querySelector('#custom-dropzone') as HTMLElement;
        let dragDropIconText = document.querySelector('.drag-icon-text') as HTMLElement;
        dropzone.style.visibility = 'unset';
        dragDropIconText.style.visibility = 'unset';
        dropzone.style.opacity = '1';
        dragDropIconText.style.opacity = '1';
    }

    public onDrop(): void {
        let dropzone = document.querySelector('#custom-dropzone') as HTMLElement;
        let dragDropIconText = document.querySelector('.drag-icon-text') as HTMLElement;
        dropzone.style.visibility = 'hidden';
        dragDropIconText.style.visibility = 'hidden';
        dropzone.style.opacity = '0';
        dragDropIconText.style.opacity = '0';
    }

    public onDragLeave(): void {
        let dropzone = document.querySelector('#custom-dropzone') as HTMLElement;
        let dragDropIconText = document.querySelector('.drag-icon-text') as HTMLElement;
        dropzone.style.visibility = 'hidden';
        dragDropIconText.style.visibility = 'hidden';
        dropzone.style.opacity = '0';
        dragDropIconText.style.opacity = '0';
    }

    public stickToTop(stickySearchTerm: string | null): void {
        const configContainer = document.getElementsByClassName('view-config-container');
        const stickyContainer = document.getElementsByClassName('sticky-content');
        if (configContainer && stickyContainer) {
            if (stickySearchTerm) {
                this.setStickyVisible(stickyContainer, true);
                this.setContainerVisible(configContainer, false);
            } else if (configContainer[1] && (configContainer[1] as HTMLElement).getBoundingClientRect().top < 100) {
                this.setStickyVisible(stickyContainer, true);
                this.setContainerVisible(configContainer, false);
            } else {
                this.setStickyVisible(stickyContainer, false);
                this.setContainerVisible(configContainer, true);
            }
        }
    }

    public applyNewStickyCondition(value: string | null): void {
        const configContainer = document.getElementsByClassName('view-config-container');
        const stickyContainer = document.getElementsByClassName('sticky-content');
        if (value) {
            this.setStickyVisible(stickyContainer, true);
            this.setContainerVisible(configContainer, false);
        } else {
            this.setStickyVisible(stickyContainer, false);
            this.setContainerVisible(configContainer, true);
        }
    }

    private setStickyVisible(stickyContainer: HTMLCollectionOf<Element>, value: boolean): void {
        if (value) {
            (stickyContainer[0] as HTMLElement).style.visibility = 'visible';
            (stickyContainer[0] as HTMLElement).style.opacity = '1';
        } else {
            (stickyContainer[0] as HTMLElement).style.visibility = 'hidden';
            (stickyContainer[0] as HTMLElement).style.opacity = '0';
        }
    }

    private setContainerVisible(configContainer: HTMLCollectionOf<Element>, value: boolean): void {
        if (value) {
            (configContainer[1] as HTMLElement).style.visibility = 'visible';
            (configContainer[1] as HTMLElement).style.opacity = '1';
        } else {
            (configContainer[1] as HTMLElement).style.visibility = 'hidden';
            (configContainer[1] as HTMLElement).style.opacity = '0';
        }
    }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { PublicationsQuery } from '../../../publication/state/publications/publications.query';
import { Publication } from '../../../publication/models/publication.model';
import { PublicationGroupsQuery } from '../../../publication-group/state/publication-groups/publication-groups.query';
import { PublicationGroup } from '../../../publication-group/models/publication-group.model';
import { LocksService } from '../services';
import { first, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { LocksQuery } from '../state/locks/locks.query';
import { UsersQuery } from '../../../modules/shared/state/users/users.query';
import { User } from '../../../modules/shared/models/user.model';
import { SectionsQuery } from '../state/sections/sections.query';
import { SectionsService, StylesheetService } from '../services';
import { StylesheetQuery } from '../state/stylesheet/stylesheet.query';

@Injectable()
export class EditorResolver implements Resolve<any> {
    constructor(
        private locksQuery: LocksQuery,
        private locksService: LocksService,
        private publicationGroupsQuery: PublicationGroupsQuery,
        private publicationsQuery: PublicationsQuery,
        private router: Router,
        private sectionsQuery: SectionsQuery,
        private sectionsService: SectionsService,
        private stylesheetQuery: StylesheetQuery,
        private stylesheetService: StylesheetService,
        private usersQuery: UsersQuery
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        const sectionId = route.firstChild?.params['sectionId'];
        const publication = this.publicationsQuery.getActive() as Publication;
        const observables = [];

        if (!this.stylesheetQuery.getValue()?.stylesheet) {
            observables.push(this.stylesheetService.getStylesheet(publication.id as string));
        }

        if (!this.sectionsQuery.getValue().loaded) {
            observables.push(this.sectionsService.getSections());
        }

        if (sectionId && sectionId !== publication.rootSectionId) {
            if (observables.length === 0) {
                return of(true);
            }

            return forkJoin(observables);
        }

        const publicationGroup = this.publicationGroupsQuery.getActive() as PublicationGroup;

        observables.push(this.locksService.getLocksForPublication(publication.id as string));

        if (observables.length === 0) {
            return of(true);
        }

        return forkJoin(observables).pipe(
            switchMap(() => {
                const firstSectionId = this.getFirstNonRootSectionId();

                return this.locksQuery.getSectionLock(firstSectionId).pipe(first());
            }),
            switchMap((lock) => {
                const loggedInUser = this.usersQuery.getLoggedInUser() as User;
                const firstSectionId = this.getFirstNonRootSectionId();

                /**
                 * Don't redirect to the first section when it is locked by another user
                 * Redirect to the editor container instead
                 */
                if (lock && lock.user.id !== loggedInUser.id) {
                    return of(true);
                }

                return this.router.navigate([
                    '/publication-groups',
                    publicationGroup.id,
                    'publications',
                    publication.id,
                    'editor',
                    'sections',
                    firstSectionId,
                ]);
            })
        );
    }

    private getFirstNonRootSectionId(): string {
        return this.sectionsQuery.getAll()[1].id as string;
    }
}

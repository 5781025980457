<div class="section-type-editor">
    <h3 class="title">{{ 'propertyEditor.sectionType' | translate }}</h3>
    <p class="description">{{ 'propertyEditor.changeTypeDescription' | translate }}</p>
    <div>
        <button
            data-cy="btn-change-type"
            mat-stroked-button
            color="accent"
            (click)="changeSectionType()"
            [disabled]="disabled">
            {{ 'sectionTypeEditor.changeType' | translate }}
        </button>
    </div>
</div>

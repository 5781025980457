<elias-base-dialog *ngIf="showDialog" (dialogClosed)="onCancel()">
    <div header class="inner-container">
        <h1>{{ 'assetBrowser.imageCropping.title' | translate }}</h1>
    </div>

    <div class="inner-container wrapper">
        <div class="side-panel">
            <h2>{{ 'assetBrowser.imageCropping.aspectRatio' | translate }}</h2>
            <mat-chip-list class="mat-chip-list-stacked">
                <mat-chip
                    style="width: auto"
                    [selected]="selectedAspectRatio === ratio.factor"
                    *ngFor="let ratio of aspectRatios$ | async"
                    (click)="changeAspectRatio(ratio.factor)">
                    {{ ratio.name }}
                </mat-chip>
                <mat-chip
                    [selected]="selectedAspectRatio === undefined"
                    (click)="changeAspectRatio()"
                    style="width: auto"
                    >{{ 'assetBrowser.imageCropping.freeMode' | translate }}</mat-chip
                >
            </mat-chip-list>
        </div>
        <div class="cropping-area">
            <div class="inner">
                <img
                    [src]="image | safeUrl"
                    class="image-to-crop"
                    alt=""
                    *ngIf="image$ | async as image; else loading" />
                <ng-template #loading>
                    <elias-shared-loading [centered]="true"></elias-shared-loading>
                </ng-template>
            </div>
        </div>
    </div>

    <footer footer>
        <div class="inner-container footer-inner">
            <div class="cancel-container">
                <button mat-button (click)="onCancel()">
                    {{ 'assetBrowser.imageCropping.cancel' | translate }}
                </button>
            </div>
            <div class="confirm-container">
                <button mat-stroked-button color="accent" [disabled]="!this.canSave" (click)="submitCrop()">
                    {{ saveButtonText | translate }}
                </button>
            </div>
        </div>
    </footer>
</elias-base-dialog>

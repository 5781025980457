import { QueryEntity } from '@datorama/akita';
import { AspectRatiosState, AspectRatiosStore } from './aspect-ratios.store';
import { AspectRatio } from '../../models/aspect-ratio.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AspectRatiosQuery extends QueryEntity<AspectRatiosState, AspectRatio> {
    constructor(protected override store: AspectRatiosStore) {
        super(store);
    }

    public getOriginalAspectRatioId(): string | undefined {
        return this.getAll().find((a) => a.factor === '0')?.id;
    }
}

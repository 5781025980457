<ng-container [formGroup]="formGroup">
    <mat-form-field appearance="outline" [hideRequiredMarker]="false" [ngClass]="{ 'has-prefix': matPrefix }">
        <mat-label>{{ label }}</mat-label>

        <span matPrefix *ngIf="matPrefix" [ngClass]="{ 'mat-prefix': matPrefix }">{{ matPrefix }} &nbsp;</span>
        <ng-container *ngIf="type === 'password'; else defaultInput">
            <input
                [attr.data-cy]="name + '-input'"
                matInput
                [formControlName]="name"
                [placeholder]="placeholder"
                [required]="required"
                [type]="isVisible ? 'password' : 'text'" />
            <ng-container *ngIf="isVisible">
                <i class="ei ei-visibility-off" (click)="isVisible = !isVisible"></i>
            </ng-container>
            <ng-container *ngIf="!isVisible">
                <i class="ei ei-visibility" (click)="isVisible = !isVisible"></i>
            </ng-container>
        </ng-container>
        <ng-template #defaultInput>
            <input
                [attr.data-cy]="name + '-input'"
                matInput
                [formControlName]="name"
                [placeholder]="placeholder"
                [required]="required" />
        </ng-template>
        <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
        <mat-error *ngIf="formGroup.get(name).hasError('required') && moduleName">
            {{ 'error.' + moduleName + '.' + name | translate }}
        </mat-error>
        <mat-error *ngIf="formGroup.get(name).hasError('required') && !moduleName">
            {{ 'error.' + name | translate }}
        </mat-error>
        <mat-error *ngIf="formGroup.get(name).errors?.pattern">
            {{ 'error.pattern.' + name | translate }}
        </mat-error>
        <mat-error
            *ngIf="
                formGroup.get(name).hasError('max') &&
                !formGroup.get(name).hasError('numberValue') &&
                !formGroup.get(name).hasError('required')
            ">
            {{ 'error.maxValue.' + name | translate }}
        </mat-error>
        <mat-error
            *ngIf="
                formGroup.get(name).hasError('min') &&
                !formGroup.get(name).hasError('numberValue') &&
                !formGroup.get(name).hasError('required')
            ">
            {{ 'error.minValue.' + name | translate }}
        </mat-error>
        <mat-error *ngIf="formGroup.get(name).hasError('minlength') && !formGroup.get(name).hasError('required')">
            {{ 'error.minlength.' + name | translate }}
        </mat-error>
        <mat-error *ngIf="formGroup.get(name).hasError('maxlength') && !formGroup.get(name).hasError('required')">
            {{ 'error.maxlength.' + name | translate }}
        </mat-error>
        <mat-error *ngIf="formGroup.get(name).errors?.numberValue && !formGroup.get(name).hasError('required')">
            {{ 'error.numberValue.' + name | translate }}
        </mat-error>
        <mat-error *ngIf="formGroup.get(name).hasError('email') && !formGroup.get(name).hasError('required')">
            {{ 'error.' + name | translate }}
        </mat-error>
        <mat-error *ngIf="formGroup.get(name).errors?.username && !formGroup.get(name).hasError('required')">
            {{ 'error.uniqueusername' | translate }}
        </mat-error>
        <mat-error *ngIf="formGroup.get(name).errors?.uniqueemail && !formGroup.get(name).hasError('required')">
            {{ 'error.uniqueemail' | translate : { username: formGroup.get(name).errors.existsUserFullName } }}
        </mat-error>
        <mat-error *ngIf="formGroup.get(name).errors?.passwordmatch && !formGroup.get(name).hasError('required')">
            {{ 'user.password.passwordmatcherror' | translate }}
        </mat-error>
    </mat-form-field>
</ng-container>

import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import Cropper from 'cropperjs';
import { AspectRatio } from '../../../modules/shared/models/aspect-ratio.model';
import { AspectRatiosQuery } from '../../../modules/shared/state/aspect-ratios/aspect-ratios.query';
import { AssetCroppingMetadataDto, CroppingEditorOutput } from '../types';
import { ImageCroppingHttpService } from '../image-cropping.http-service';
import { CroppingAssetConfirmationComponent } from '../cropping-asset-confirmation/cropping-asset-confirmation.component';
import { PublicationUsingAsset } from '../../../modules/shared/models/publication-using-asset.model';
import { AssetsService } from '../../../modules/shared/services/assets.service';

@Component({
    selector: 'elias-cropping-editor',
    templateUrl: './cropping-editor.component.html',
    styleUrls: ['./cropping-editor.component.scss'],
})
export class CroppingEditorComponent implements OnInit {
    public image$: Observable<string | undefined>;
    public aspectRatios$: Observable<AspectRatio[]>;
    public showDialog: boolean = true;
    public canSave: boolean = false;
    public selectedAspectRatio?: string;

    private assetId: string = '';
    private nodeId?: string;
    private publicationsUsingAsset: PublicationUsingAsset[] = [];
    private cropper?: Cropper;

    get saveButtonText(): string {
        if (this.publicationsUsingAsset.length === 0) {
            return 'assetBrowser.imageCropping.saveAsNew';
        }

        return 'assetBrowser.imageCropping.save';
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private aspectRatiosQuery: AspectRatiosQuery,
        private assetsService: AssetsService,
        private croppingAssetConfirmation: MatDialog,
        private dialogRef: MatDialogRef<CroppingEditorComponent>,
        private imageCroppingHttpService: ImageCroppingHttpService
    ) {
        this.image$ = this.imageCroppingHttpService.getImageForCropping(data['assetId']).pipe(
            tap(() => {
                setTimeout(() => this.instantiateCropper());
            })
        );

        this.assetId = data['assetId'];
        this.nodeId = data['nodeId'];
        this.aspectRatios$ = this.aspectRatiosQuery.selectAll();
    }

    ngOnInit(): void {
        this.assetsService
            .getPublicationsUsingAsset(this.assetId, this.nodeId)
            .subscribe((data: PublicationUsingAsset[]) => {
                this.publicationsUsingAsset = data;
                this.canSave = true;
            });
    }

    public changeAspectRatio(aspect?: string): void {
        const imageData = this.cropper?.getImageData();
        let calculatedAspectRatioFactor = aspect;

        // original aspect ratio factor
        if (aspect === '0' && imageData) {
            calculatedAspectRatioFactor = (imageData.naturalWidth / imageData.naturalHeight).toString();
        }

        this.selectedAspectRatio = aspect;
        this.cropper?.setAspectRatio(calculatedAspectRatioFactor ? parseFloat(calculatedAspectRatioFactor) : NaN);
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public submitCrop(): void {
        let assetCroppingMetadataDto: AssetCroppingMetadataDto = {
            replaced: false,
            croppingEditorOutput: this.getOutput(),
        };

        if (!this.publicationsUsingAsset || this.publicationsUsingAsset?.length === 0) {
            assetCroppingMetadataDto.replaced = true;
            this.dialogRef.close(assetCroppingMetadataDto);
            return;
        }

        this.showDialog = false;

        const confirmationDialog = this.croppingAssetConfirmation.open(CroppingAssetConfirmationComponent, {
            width: '700px',
            height: '520px',
            data: {
                listOfPublications: this.publicationsUsingAsset,
            },
        });

        confirmationDialog.afterClosed().subscribe((replaceAsset?: boolean) => {
            // Cancelled
            if (replaceAsset === undefined) {
                this.dialogRef.close();
                return;
            }

            assetCroppingMetadataDto.replaced = replaceAsset;
            this.dialogRef.close(assetCroppingMetadataDto);
        });
    }

    private instantiateCropper(): void {
        const htmlImageElement = document.querySelector('.image-to-crop') as HTMLImageElement;
        if (!htmlImageElement) {
            return;
        }

        if (this.cropper) {
            this.cropper.destroy();
        }

        this.cropper = new Cropper(htmlImageElement, {
            zoomable: false,
            viewMode: 2,
            background: false,
        });

        // Initialize aspect ratio
        this.changeAspectRatio(this.selectedAspectRatio);
    }

    private getOutput(): CroppingEditorOutput {
        if (!this.cropper) {
            throw new Error('Cropper instance is not initialized');
        }

        const data = this.cropper.getData(true);
        const image = this.cropper.getImageData();

        return {
            image: {
                height: image.naturalHeight,
                width: image.naturalWidth,
            },
            mask: {
                height: data.height,
                rotate: data.rotate,
                scaleX: data.scaleX,
                scaleY: data.scaleY,
                width: data.width,
                x: data.x,
                y: data.y,
            },
        };
    }
}

<div
    class="avatar avatar-size-{{ size }}"
    data-cy="avatar"
    [ngClass]="{ 'disabled-avatar': disabled }"
    [matTooltip]="label"
    [matTooltipDisabled]="hideToolTip"
    [ngStyle]="{ 'background-color': backgroundColor }">
    <img *ngIf="image; else initialsTemplate" class="profile-image" [src]="image" [alt]="label" />
    <ng-template #initialsTemplate>
        <div *ngIf="backgroundColor; else defaultAvatar" class="initials">{{ initials }}</div>
    </ng-template>
    <ng-template #defaultAvatar>
        <img class="elias-logo" src="assets/elias-avatar.png" alt="eliasPublisher Avatar" />
    </ng-template>
</div>

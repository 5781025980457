import { QueryEntity } from '@datorama/akita';
import { AssetsState, AssetsStore } from './assets.store';
import { Asset } from '../../models/asset.model';
import { Injectable } from '@angular/core';
import { Excel } from '../../../../editor/editor/models/excel.model';

@Injectable({
    providedIn: 'root',
})
export class AssetsQuery extends QueryEntity<AssetsState, Asset> {
    constructor(protected override store: AssetsStore) {
        super(store);
    }

    public getExcelEntity(id: any): Excel | undefined {
        const asset = this.getEntity(id);

        if (asset?.type === 'excel') {
            return asset as Excel;
        }

        return undefined;
    }
}

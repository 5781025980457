import { ChannelsState, ChannelsStore } from './channels.store';
import { Channel } from '../../models/channel.model';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ChannelsQuery extends QueryEntity<ChannelsState, Channel> {
    constructor(protected override store: ChannelsStore) {
        super(store);
    }

    getEnabledChannels() {
        return this.getAll().filter((channel) => channel.enabled);
    }

    hasWebViewerChannel(): boolean {
        return this.getAll().filter((channel) => channel.renderer === 'web-viewer').length > 0;
    }
}

<div [ngSwitch]="config.type" style="word-break: break-word">
    <blockquote [innerHTML]="content || '&nbsp;' | html" *ngSwitchCase="'quote'"></blockquote>
    <div [innerHTML]="content || '&nbsp;' | html" *ngSwitchCase="'lead'"></div>
    <div class="{{ titleClass }}" *ngSwitchCase="'title'">
        <p [outerHTML]="titleContent || '&nbsp;' | html"></p>
        <span class="small" *ngIf="additionalText" [innerHTML]="additionalText | html"></span>
    </div>
    <div [innerHTML]="content || '&nbsp;' | html" *ngSwitchCase="'footnote'"></div>
    <div [innerHTML]="content || '&nbsp;' | html" *ngSwitchCase="'disclaimer'"></div>
    <small [innerHTML]="content || '&nbsp;' | html" *ngSwitchCase="'small'"></small>
    <dfn [innerHTML]="content || '&nbsp;' | html" *ngSwitchCase="'big'"></dfn>
    <div
        [ngClass]="{ multicolumn: node['multiColumn'], 'custom-breaks': hasOwnColBreaks(content) }"
        [innerHTML]="replaceColbreaks(content) || '&nbsp;' | html"
        *ngSwitchDefault></div>
</div>

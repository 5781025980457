<div [ngSwitch]="type" style="word-break: break-word">
    <div
        class="editor pe-reset"
        id="{{ containerId }}"
        [ngClass]="{
            multicolumn: (nodeViewModel | async)?.multiColumn
        }"
        [innerHTML]="localContent | html">
        <elias-tinymce-editor
            [content]="localContent"
            [elementId]="node?.id"
            [config]="config"
            (contentChange)="onContentChange($event)"></elias-tinymce-editor>
    </div>
</div>

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Thumbnail } from '../../../../modules/shared/models/thumbnail.model';
import { Asset } from '../../../../modules/shared/models/asset.model';

export interface AssetBrowserState extends EntityState<Asset> {
    loaded: boolean;
    thumbnails: { [k: string]: Thumbnail };
    ui: {
        viewOption: number;
        filters: {
            collection: string | null;
            storage: string | null;
            fileType: string | null;
        };
        sort: {
            key: string | null;
            direction: string | null;
        };
    };
    totalNumberOfPages: number;
    currentPage: number;
    totalDataLength: number;
}

const initialState: AssetBrowserState = {
    loaded: false,
    thumbnails: {},
    ui: {
        viewOption: 0,
        filters: {
            collection: null,
            storage: null,
            fileType: null,
        },
        sort: {
            key: null,
            direction: null,
        },
    },
    currentPage: 1,
    totalDataLength: 0,
    totalNumberOfPages: 1,
};

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'AssetBrowser', resettable: true })
export class AssetBrowserStore extends EntityStore<AssetBrowserState, Asset> {
    constructor() {
        super(initialState);
    }
}

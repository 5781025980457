import { Component, Injector, OnInit } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import { FormArray, FormBuilder } from '@angular/forms';
import { ChannelsQuery } from '../../../../shared/state/channels/channels.query';

@Component({
    selector: 'elias-form-element-channel-settings',
    templateUrl: './form-channel-settings.component.html',
    styleUrls: ['./form-channel-settings.component.scss'],
})
export class FormChannelSettingsComponent extends BasicFormElementComponent implements OnInit {
    constructor(private fb: FormBuilder, injector: Injector, public channelQuery: ChannelsQuery) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.initValue) {
            this.setChannelSettings(this.initValue);
        }
    }

    get channelSettings(): FormArray {
        return this.formGroup.get(this.name) as FormArray;
    }

    setChannelSettings(channelSettings: any[]) {
        const channelSettingsFGs = channelSettings.map((channelSetting) => this.fb.group(channelSetting));
        const channelSettingsFormArray = this.fb.array(channelSettingsFGs);
        this.formGroup.setControl(this.name, channelSettingsFormArray);
    }

    toggleActivation(i) {
        this.channelSettings.controls[i].value.active = !this.channelSettings.controls[i].value.active;
    }

    getIcons(icon) {
        if (icon === 'msword') return 'msword';
        if (icon === 'typo3') return 'typo3';
        if (icon === 'wordpress') return 'wordpress';
        if (icon === 'webviewer') return 'webviewer';
    }

    trackByIndex(index, item) {
        return index;
    }
}

<div [formGroup]="formGroup">
    <mat-form-field appearance="outline" [hideRequiredMarker]="false">
        <mat-label>{{ label }}</mat-label>
        <mat-select [attr.data-cy]="'form-select-' + name" [formControlName]="name" [required]="required">
            <ng-container *ngFor="let option of options; trackBy: trackByIndex">
                <mat-option [attr.data-cy]="option.key" *ngIf="!option.disabled" [value]="option.key">
                    {{ option.value | translate }}
                </mat-option>
            </ng-container>
        </mat-select>
        <mat-error *ngIf="formGroup.get(name).hasError('required')">
            {{ 'error.' + name | translate }}
        </mat-error>
        <mat-hint>{{ hint | translate }}</mat-hint>
    </mat-form-field>
</div>

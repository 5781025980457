<ng-container *ngIf="asset">
    <ng-container *ngIf="isImage">
        <elias-image [path]="asset.thumbnail?.content ?? ''"></elias-image>
    </ng-container>
    <ng-container *ngIf="isExcel">
        <i class="ei ei-file-xls file-icon icon-size-{{ iconSize }}"></i>
    </ng-container>
    <ng-container *ngIf="!isImage && !isExcel">
        <i class="ei ei-file-doc file-icon icon-size-{{ iconSize }}"></i>
    </ng-container>
</ng-container>

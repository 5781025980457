import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Asset } from '../../models/asset.model';
import { Injectable } from '@angular/core';

export interface AssetsState extends EntityState<Asset> {
    loaded: boolean;
}

const initialState = {
    loaded: false,
};

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Assets', resettable: true })
export class AssetsStore extends EntityStore<AssetsState, Asset> {
    constructor() {
        super(initialState);
    }
}

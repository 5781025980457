import { Component, Input } from '@angular/core';
import { Asset } from '../../models/asset.model';
import { ImageTypes } from '../../../../editor/asset-browser/models/image-types.model';
import { SpreadsheetTypes } from '../../../../editor/asset-browser/models/spreadsheet-types.model';

@Component({
    selector: 'elias-asset-thumbnail',
    templateUrl: './asset-thumbnail.component.html',
    styleUrls: ['./asset-thumbnail.component.scss'],
})
export class AssetThumbnailComponent {
    @Input() allowFallbackImage = false;
    @Input() asset?: Asset;
    @Input() iconSize: 'small' | 'medium' | 'big' = 'medium';

    get isImage(): boolean {
        if (!this.asset) {
            return false;
        }

        return ImageTypes.includes(this.asset.mimeType);
    }

    get isExcel(): boolean {
        if (!this.asset) {
            return false;
        }

        return SpreadsheetTypes.includes(this.asset.mimeType);
    }
}

<i *ngIf="showCloseButton" class="ei ei-close close-icon theme-{{ colorTheme }}" (click)="onCloseIconClicked()"></i>

<div class="header">
    <ng-content select="[header]"></ng-content>
</div>
<div class="scrollable-area">
    <ng-content></ng-content>
</div>
<div class="footer">
    <ng-content select="[footer]"></ng-content>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'elias-shared-view-option-toggle',
    templateUrl: './view-option-toggle.component.html',
    styleUrls: ['./view-option-toggle.component.scss'],
})
export class ViewOptionToggleComponent {
    @Input() viewOption: number;

    /**
     * Event Emitter to send the selected view option to publicationlist.component
     */
    @Output() toggleViewEvent = new EventEmitter();

    constructor() {}

    changeViewOption(viewOption: number) {
        this.toggleViewEvent.emit(viewOption);
    }
}

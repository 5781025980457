import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DomainService {
    public readonly apiBaseUrl: string;

    constructor() {
        const domain = (<any>window).location.hostname;
        const port = (<any>window).location.port;

        this.apiBaseUrl = domain === 'localhost' && port === '4200' ? 'https://elias.ddev.site/api/v1' : '/api/v1';
    }
}

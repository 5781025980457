import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { PropertyEditorConfigurationCollection } from '../models/property-editor-configuration-collection.model';
import { PropertyEditorStore } from '../state/property-editor/property-editor.store';
import { PropertyEditorConfiguration } from '../models/property-editor-configuration.model';
import { Property } from '../models/property.model';
import { PropertyEditorQuery } from '../state/property-editor/property-editor.query';
import { Observable, throwError } from 'rxjs';
import { DomainService } from '../../../modules/core/services/domain.service';

@Injectable()
export class PropertyEditorService {
    constructor(
        private domainService: DomainService,
        private http: HttpClient,
        private propertyEditorQuery: PropertyEditorQuery,
        private propertyEditorStore: PropertyEditorStore
    ) {}

    loadPropertyEditorConfigurations(publicationId: string): Observable<PropertyEditorConfigurationCollection> {
        this.propertyEditorStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/publications/${publicationId}/property-editor-configurations`;

        return this.http.get<PropertyEditorConfigurationCollection>(url).pipe(
            tap((configuration: any) => {
                const nodeProperties = configuration.nodes.reduce(
                    (
                        entities: { [type: string]: Property },
                        propertyEditorConfiguration: PropertyEditorConfiguration
                    ) => {
                        return {
                            ...entities,
                            [propertyEditorConfiguration.type]: propertyEditorConfiguration,
                        };
                    },
                    {
                        ...this.propertyEditorQuery.getValue().nodeConfigurations,
                    }
                );

                const sectionProperties = configuration.sections.reduce(
                    (
                        entities: { [type: string]: Property },
                        propertyEditorConfiguration: PropertyEditorConfiguration
                    ) => {
                        return {
                            ...entities,
                            [propertyEditorConfiguration.type]: propertyEditorConfiguration,
                        };
                    },
                    {
                        ...this.propertyEditorQuery.getValue().sectionConfigurations,
                    }
                );

                this.propertyEditorStore.update({
                    nodeConfigurations: nodeProperties,
                    sectionConfigurations: sectionProperties,
                    loading: false,
                    loaded: true,
                });
            }),
            catchError((error: any) => throwError(error))
        );
    }
}

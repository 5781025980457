<elias-base-dialog (dialogClosed)="onCancel()" cdkTrapFocus [cdkTrapFocusAutoCapture]="autofocus">
    <ng-container header>
        <h1 [ngClass]="{ marginbot: selectedStep.subtitle === '' }">{{ selectedStep.title }}</h1>
        <p *ngIf="selectedStep.subtitle" [innerHTML]="selectedStep.subtitle"></p>
    </ng-container>

    <div [style.display]="selected ? 'block' : 'none'">
        <!-- Content from the CdkStep is projected here -->
        <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
    </div>

    <footer footer class="step-navigation-bar" *ngIf="mode !== 'publication' || selectedIndex > 0">
        <button
            class="stepper-btn prev"
            mat-button
            cdkStepperPrevious
            fxFlex="120px"
            *ngIf="!getActiveStep().hideBackButton && selectedIndex > 0">
            {{ 'stepper.previous' | translate }}
        </button>
        <button
            class="stepper-btn prev"
            mat-button
            (click)="onCancel()"
            fxFlex="120px"
            *ngIf="!getActiveStep().hideBackButton && selectedIndex === 0">
            {{ 'stepper.cancel' | translate }}
        </button>
        <div class="step-dot-container">
            <div
                class="step-dot"
                *ngFor="let step of steps; trackBy: trackByIndex; let i = index"
                [ngClass]="{ active: selectedIndex === i, hidden: getActiveStep().hideDots }"
                (click)="onClick(i)"></div>
        </div>
        <button
            data-cy="stepper-next"
            class="stepper-btn next"
            mat-stroked-button
            fxFlex="120px"
            *ngIf="!getActiveStep().isSave && !getActiveStep().isLoading">
            {{ 'stepper.next' | translate }}
        </button>
        <button
            data-cy="stepper-next"
            class="stepper-btn next"
            mat-flat-button
            color="accent"
            cdkStepperNext
            fxFlex="120px"
            *ngIf="getActiveStep().isSave && !getActiveStep().isLoading">
            <ng-container *ngIf="getActiveStep().nextButtonText">{{ 'stepper.finish' | translate }}</ng-container>
            <ng-container *ngIf="!getActiveStep().nextButtonText">{{ 'stepper.next' | translate }}</ng-container>
        </button>
        <button class="stepper-btn next" mat-stroked-button fxFlex="120px" *ngIf="getActiveStep().isLoading">
            <ng-container>
                <mat-spinner [diameter]="30" color="accent"></mat-spinner>
            </ng-container>
        </button>
    </footer>
</elias-base-dialog>

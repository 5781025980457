<div class="container property-editor-container">
    <div>
        <elias-shared-sidebar-title title="{{ 'propertyEditor.widgetTitle' | translate }}" [buttonFlexLayout]="'end'">
            <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                class="menu-button-icon"
                *ngIf="isSelectedNode(selectedElement)">
                <i class="ei ei-more-vert"></i>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="copyNode()">
                    <i class="ei ei-copy"></i>{{ 'actions.copy' | translate }}
                </button>
                <button mat-menu-item (click)="removeNode()">
                    <i class="ei ei-trash"></i>{{ 'actions.delete' | translate }}
                </button>
            </mat-menu>
        </elias-shared-sidebar-title>
        <ng-container *ngIf="isSelectedSection(selectedElement)">
            <elias-section-type-editor [section]="selectedElement.element"></elias-section-type-editor>
        </ng-container>
        <elias-form
            [formConfig]="formConfig"
            translationKey="propertyEditor"
            [data]="initData"
            [hideButtons]="true"
            (formSubmitted)="onFormSubmitted($event)"
            #form></elias-form>
        <div fxLayout="row" class="action-buttons" fxLayoutGap="10%" *ngIf="form.canSave()" [@slideInOut]>
            <button
                color="accent"
                class="btn-save"
                data-cy="btn-save"
                mat-flat-button
                [disabled]="!form.canSave() || isSaving"
                (click)="form.onSubmit()">
                <ng-container *ngIf="!isSaving">
                    {{ 'actions.save' | translate }}
                </ng-container>
                <ng-container *ngIf="isSaving">
                    <mat-spinner [diameter]="25"></mat-spinner>
                </ng-container>
            </button>
            <button mat-button (click)="close()">{{ 'actions.cancel' | translate }}</button>
            <div class="changes" *ngIf="form.isDirty() && form.getNumberOfChanges() === 1">
                {{ form.getNumberOfChanges() }} {{ 'shared.change' | translate }}
            </div>
            <div class="changes" *ngIf="form.isDirty() && form.getNumberOfChanges() > 1">
                {{ form.getNumberOfChanges() }} {{ 'shared.changes' | translate }}
            </div>
        </div>
    </div>
</div>

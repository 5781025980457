import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { RequestDto } from '../../editor/models/dto/request-dto.model';
import { catchError, last, tap } from 'rxjs/operators';
import { AssetStoragesService } from '../services/asset-storages.service';
import { AssetStorage } from '../models/asset-storage.model';
import { UrlParamsService } from '../../../modules/core/services/url-params.service';
import { AssetBrowserService } from '../services/asset-browser.service';
import { AssetBrowserQuery } from '../state/asset-browser/asset-browser.query';

@Injectable()
export class AssetBrowserDeepLinksResolver implements Resolve<any> {
    constructor(
        private assetBrowserQuery: AssetBrowserQuery,
        private assetBrowserService: AssetBrowserService,
        private assetStoragesService: AssetStoragesService,
        private router: Router,
        private urlParamsService: UrlParamsService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const assetId = this.urlParamsService.getNestedParamFromActivatedRouteSnapshot(route, 'asset');

        if (!assetId) {
            return of(true);
        }

        const observables: Observable<any>[] = [];
        const publicationGroupId = this.urlParamsService.getNestedParamFromActivatedRouteSnapshot(
            route,
            'publicationGroupId'
        );

        if (!this.assetBrowserQuery.hasEntity(assetId)) {
            const payload = new RequestDto({ assetId: assetId });
            observables.push(this.assetBrowserService.loadSingleAsset(payload));
        }

        let availableAssetStorages: AssetStorage[];

        observables.push(
            this.assetStoragesService.getAssetStorages(new RequestDto({ pubGroupId: publicationGroupId })).pipe(
                tap((assetStorages) => {
                    availableAssetStorages = assetStorages;
                })
            )
        );

        return forkJoin(observables).pipe(
            last(),
            tap(() => {
                const { storage } = this.assetBrowserQuery.getEntity(assetId);

                if (availableAssetStorages.some(({ id }) => id === storage.id)) {
                    return of(true);
                }
                return this.router.navigateByUrl(this.getRedirectUrl(state));
            }),
            catchError(() => this.router.navigateByUrl(this.getRedirectUrl(state)))
        );
    }

    private getRedirectUrl(state: RouterStateSnapshot): string {
        return state.url.split(';asset')[0];
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'elias-base-dialog',
    templateUrl: './base-dialog.component.html',
    styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent {
    @Input() showCloseButton: boolean = true;
    @Input() colorTheme: 'light' | 'dark' = 'light';
    @Output() dialogClosed = new EventEmitter<void>();

    constructor() {}

    public onCloseIconClicked(): void {
        this.dialogClosed.emit();
    }
}

import { Injectable } from '@angular/core';
import { Asset } from '../../modules/shared/models/asset.model';
import { catchError, map, tap } from 'rxjs/operators';
import { RequestDto } from '../editor/models/dto/request-dto.model';
import { Observable, throwError } from 'rxjs';
import { ImageDto } from '../../modules/shared/models/image-dto.model';
import { DomainService } from '../../modules/core/services/domain.service';
import { HttpClient } from '@angular/common/http';
import { ImagesService } from '../editor-container/services/images.service';
import { AspectRatiosQuery } from '../../modules/shared/state/aspect-ratios/aspect-ratios.query';
import { AssetsStore } from '../../modules/shared/state/assets/assets.store';
import { ImagesStore } from '../editor-container/state/images/images.store';

@Injectable({
    providedIn: 'root',
})
export class ImageCroppingHttpService {
    constructor(
        private assetsStore: AssetsStore,
        private aspectRatiosQuery: AspectRatiosQuery,
        private domainService: DomainService,
        private http: HttpClient,
        private imagesService: ImagesService,
        private imagesStore: ImagesStore
    ) {}

    public getImageForCropping(assetId: string): Observable<string> {
        const aspectRatioId = this.aspectRatiosQuery.getOriginalAspectRatioId();

        return this.http.get<ImageDto>(`${this.domainService.apiBaseUrl}/images/${assetId}/${aspectRatioId}`).pipe(
            map((image) => URL.createObjectURL(this.imagesService.b64toBlob(image.content))),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    public cropImage(payload: RequestDto): Observable<Asset> {
        const url = `${this.domainService.apiBaseUrl}/assets/${payload.getParam('assetId')}/croppings`;

        return this.http.post<Asset>(url, payload.body, { params: payload.queryParams }).pipe(
            tap((asset) => {
                if (asset.thumbnail) {
                    asset.thumbnail.content = URL.createObjectURL(
                        this.imagesService.b64toBlob(asset.thumbnail.content)
                    );
                }
                this.assetsStore.upsert(asset.id, asset);

                // Force the refresh
                this.imagesStore.remove(asset.id);
            }),
            catchError((error: any) => throwError(error))
        );
    }
}

<div [formGroup]="formGroup" class="asset-image">
    <div *ngIf="asset" class="asset-view">
        <elias-shared-asset-details [asset]="asset"></elias-shared-asset-details>
    </div>
    <div class="change-image-button">
        <elias-assetbrowser-button
            [allowedTypes]="allowedTypes"
            [single]="true"
            (assetsSelected)="onSelect($event)"
            *ngIf="!fileUpload"
            text="{{ 'assetBrowser.change.' + text }}"
            [selectedAsset]="asset"></elias-assetbrowser-button>
        <ng-container *ngIf="!(text === 'image' || text === 'images')">
            <button mat-stroked-button color="accent" (click)="removeAsset()" *ngIf="asset">
                {{ 'assetBrowser.file.delete' | translate }}
            </button>
        </ng-container>
    </div>
</div>

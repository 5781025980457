import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { ExcelDataService } from '../../../service/excel-data.service';
import { AssetsQuery } from '../../../../shared/state/assets/assets.query';

@Component({
    selector: 'elias-form-element-excel-worksheet',
    templateUrl: './form-excel-worksheet.component.html',
    styleUrls: ['./form-excel-worksheet.component.scss'],
})
export class FormExcelWorksheetComponent extends BasicFormElementComponent implements OnInit, OnDestroy {
    worksheetsOptions = [];
    excelSubscription: Subscription = null;
    selectedValue;

    constructor(private assetsQuery: AssetsQuery, injector: Injector, private dataService: ExcelDataService) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();
        this.dataService.data$.subscribe((val) => {
            this.worksheetsOptions = val;
        });

        if (this.initValue) {
            this.selectedValue = _.cloneDeep(this.initValue);
        }

        const id = this.formGroup.get('excelAsset').value;
        const asset = this.assetsQuery.getExcelEntity(id);
        if (id && asset) {
            this.worksheetsOptions = asset.worksheets;
        }

        this.formGroup.get(this.name).setValue(this.selectedValue);
    }

    onWorksheetSelect(selection) {
        this.formGroup.get(this.name).setValue(selection.value);
    }

    ngOnDestroy() {
        if (this.excelSubscription != null) {
            this.excelSubscription.unsubscribe();
        }
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomainService } from '../../../modules/core/services/domain.service';
import { Excel } from '../models/excel.model';
import { Observable, throwError } from 'rxjs';
import { RequestDto } from '../models/dto/request-dto.model';
import { catchError, tap } from 'rxjs/operators';
import { AssetsStore } from '../../../modules/shared/state/assets/assets.store';

@Injectable()
export class ExcelService {
    constructor(private assetsStore: AssetsStore, private domainService: DomainService, private http: HttpClient) {}

    getExcelForSection(payload: RequestDto): Observable<Excel[]> {
        this.assetsStore.setLoading(true);

        const url = `${this.domainService.apiBaseUrl}/sections/${payload.getParam('sectionId')}/excel`;
        return this.http.get<Excel[]>(url).pipe(
            tap((excel) => {
                this.assetsStore.upsertMany(excel);
                this.assetsStore.setLoading(false);
                this.assetsStore.update({ loaded: true });
            }),
            catchError((error) => throwError(error))
        );
    }
}

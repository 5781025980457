import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeleteComponent } from '../../../../modules/shared/dialogs/delete/delete.component';
import { MatDialog } from '@angular/material/dialog';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { RequestDto } from '../../../editor/models/dto/request-dto.model';
import { map } from 'rxjs/operators';
import { Asset } from '../../../../modules/shared/models/asset.model';
import { saveAs } from 'file-saver';
import { PublicationsQuery } from '../../../../publication/state/publications/publications.query';
import { Router } from '@angular/router';
import { EditorContainerService } from '../../../editor-container/services/editor-container.service';
import { PublicationGroupsQuery } from '../../../../publication-group/state/publication-groups/publication-groups.query';
import { PublicationGroup } from '../../../../publication-group/models/publication-group.model';
import { SystemInformationQuery } from '../../../../modules/shared/state/system-information/system-information.query';
import { FormConfig } from '../../../../modules/forms/models/form-config.model';
import { FlashMessageService } from '../../../../modules/core/services/flash-message.service';
import { ImageTypes } from '../../models/image-types.model';
import { SpreadsheetTypes } from '../../models/spreadsheet-types.model';
import { SectionUsingAsset } from '../../../../modules/shared/models/section-using-asset.model';
import { AssetBrowserService } from '../../services/asset-browser.service';
import { ImageCroppingService } from '../../../image-cropping/image-cropping.service';
import { AssetBrowserStore } from '../../state/asset-browser/asset-browser.store';

@Component({
    selector: 'elias-asset-management',
    templateUrl: './asset-management.component.html',
    styleUrls: ['./asset-management.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateY(100%)' }),
                animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
            ]),
            transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(100%)' }))]),
        ]),
    ],
})
export class AssetManagementComponent implements OnInit {
    @Input() asset?: Asset;
    @Input() formConfig: FormConfig = { elements: [] };

    @Output() delete = new EventEmitter<Asset>();
    @Output() replace = new EventEmitter<Asset>();
    @Output() save = new EventEmitter<any>();

    public isSaving = false;
    public sectionsUsingAsset: SectionUsingAsset[] = [];
    public isImageCroppingFeatureEnabled: boolean;

    get replacementMimeTypes(): string {
        if (!this.asset) {
            return '';
        }

        if (ImageTypes.includes(this.asset.mimeType)) {
            return ImageTypes.toString();
        }

        if (SpreadsheetTypes.includes(this.asset.mimeType)) {
            return SpreadsheetTypes.toString();
        }

        return '';
    }

    get isImage(): boolean {
        return this.asset?.type === 'image';
    }

    get isCroppable(): boolean {
        return this.isImageCroppingFeatureEnabled && this.isImage;
    }

    constructor(
        public dialog: MatDialog,
        public publicationGroupQuery: PublicationGroupsQuery,
        public publicationsQuery: PublicationsQuery,
        private assetBrowserService: AssetBrowserService,
        private assetBrowserStore: AssetBrowserStore,
        private editorContainerService: EditorContainerService,
        private flashMessageService: FlashMessageService,
        private imageCroppingService: ImageCroppingService,
        private router: Router,
        private sideNavService: SideNavService,
        private systemInformationQuery: SystemInformationQuery
    ) {
        this.isImageCroppingFeatureEnabled =
            this.systemInformationQuery.getValue().featureFlags['image_cropping'] ?? false;
    }

    ngOnInit() {
        if (!this.asset) {
            return;
        }

        this.assetBrowserService
            .getSectionsUsingAsset(this.asset.id)
            .subscribe((data) => (this.sectionsUsingAsset = data));
    }

    public remove(): void {
        const modalRef = this.dialog.open(DeleteComponent, {
            data: {
                type: 'file',
            },
        });

        modalRef.afterClosed().subscribe((remove) => {
            if (remove) {
                this.delete.emit(this.asset);
            }
        });
    }

    public onFormSubmitted(props: any): void {
        this.isSaving = true;
        this.save.emit(props);
    }

    public close(): void {
        this.sideNavService.close();
    }

    public download(): void {
        const publicationGroupId = (this.publicationGroupQuery.getActive() as PublicationGroup).id.toString();
        const asset = this.asset;
        const payload = new RequestDto({ asset }, { pubGroupId: publicationGroupId }, {});

        this.assetBrowserService
            .downloadAsset(payload)
            .pipe(
                map((response: any) => {
                    const { name, extension }: Asset = payload.getParam('asset');
                    const filename = name.endsWith(`.${extension}`) ? name : `${name}.${extension}`;

                    saveAs(response, filename);
                })
            )
            .subscribe();
    }

    public replaceFile(e: Event): void {
        const target = e.target as HTMLInputElement;
        if (!target.files || !target.files[0] || !this.asset) {
            return;
        }

        const file = target.files[0];
        const publicationId = this.publicationsQuery.getActiveId() as string;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('assetStorage', this.asset.storage.id);
        formData.append('publication', publicationId);
        formData.append('asset', this.asset.id);
        formData.append('assetType', this.asset.mimeType);

        // Reset file input
        target.value = '';

        this.assetBrowserService.uploadAsset(formData, this.asset.storage.type).subscribe(
            () => {
                this.flashMessageService.showTranslatedInfo('propertyEditor.asset.success');
                this.replace.emit(this.asset);
            },
            (response) => {
                this.flashMessageService.showError(response.error.error);
            }
        );
    }

    public getLanguage(reference: SectionUsingAsset): string {
        return this.systemInformationQuery.getLanguages().get(reference.locale)?.name || '';
    }

    public navigateToPublicationSection(reference: SectionUsingAsset): void {
        this.editorContainerService.resetStore();
        this.router.navigate([
            '/publication-groups',
            reference.publicationGroupId,
            'publications',
            reference.publicationId,
            'editor',
            'sections',
            reference.sectionId,
        ]);
    }

    public cropImage(): void {
        if (!this.asset) {
            return;
        }

        this.imageCroppingService.openCroppingEditor(this.asset.id).subscribe((result) => {
            const { replaced, asset } = result;

            this.assetBrowserStore.update(asset.id, asset);
            replaced ? this.replace.emit(asset) : this.save.emit(result);
        });
    }
}

<div [formGroup]="formGroup">
    <div formArrayName="{{ name }}" fxLayout="row wrap" fxLayoutGap="20px grid">
        <div *ngFor="let channelSetting of initValue; trackBy: trackByIndex; let i = index" [formGroupName]="i">
            <mat-card
                class="channel"
                [ngClass]="{
                    inactive: !channelSettings.controls[i].value.active,
                    selected: channelSettings.controls[i].value.active
                }"
                (click)="toggleActivation(i)"
                *ngIf="channelQuery.selectEntity(channelSetting.name) | async as channel">
                <mat-card-header fxLayoutGap="10px">
                    <div fxLayout="row" fxLayoutGap="10px">
                        <elias-shared-channel-icon [icon]="channel.icon" size="small"></elias-shared-channel-icon>
                        <mat-card-title class="card-title">{{ channelSetting.label }}</mat-card-title>
                    </div>
                    <i *ngIf="channelSettings.controls[i].value.active" class="ei ei-done done"></i>
                </mat-card-header>
            </mat-card>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Node } from '../models/node/node.model';
import { catchError, tap } from 'rxjs/operators';
import { NodeDto } from '../models/dto/node-dto.model';
import { NodesStore } from '../state/nodes/nodes.store';
import { NodeViewModel } from '../viewmodels/node.viewmodel';
import { NodesQuery } from '../state/nodes/nodes.query';
import { RequestDto } from '../models/dto/request-dto.model';
import { ImagesService } from '../../editor-container/services/images.service';
import { ChartsService } from './charts.service';
import { DomainService } from '../../../modules/core/services/domain.service';
import { UiService } from '../../../modules/core/services/ui.service';
import { MapService } from './map.service';
import { NodeType } from '../models/node/node-type.model';

@Injectable()
export class NodesService {
    constructor(
        private chartsService: ChartsService,
        private domainService: DomainService,
        private http: HttpClient,
        private imagesService: ImagesService,
        private nodesQuery: NodesQuery,
        private nodesStore: NodesStore,
        private uiService: UiService,
        private nodeViewModel: NodeViewModel,
        private mapService: MapService
    ) {}

    /** GET nodes for specified section from the server */
    getNodesForSection(payload: NodeDto): Observable<Node[]> {
        this.nodesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${payload.getParam('sectionId')}/nodes`;
        return this.http.get<Node[]>(url).pipe(
            tap((nodes) => {
                this.nodesStore.set(nodes);
                this.nodesStore.setLoading(false);
                this.nodesStore.update({
                    loaded: true,
                    loadedNodesForSectionId: payload.getParam('sectionId'),
                });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    createNode(payload: NodeDto): Observable<{ nodes: Node[]; node: Node }> {
        this.nodesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/sections/${payload.getParam(
            'sectionId'
        )}/nodes/${payload.getParam('type')}${payload.getQueryString()}`;
        return this.http.post<{ nodes: Node[]; node: Node }>(url, payload.body).pipe(
            tap((response) => {
                this.nodesStore.upsertMany(response.nodes);
                this.updateEditing(response.node);
                this.nodesStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    copyNode(nodeId: string): Observable<{ nodes: Node[]; node: Node }> {
        this.nodesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/nodes/${nodeId}/copy`;

        return this.http.post<{ nodes: Node[]; node: Node }>(url, {}).pipe(
            tap((response) => {
                this.nodesStore.upsertMany(response.nodes);
                // this.updateEditing(response.node);
                this.nodeViewModel.set(response.node);
                this.nodesStore.update(response.node.id, { editing: true });
                this.nodesStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    updateEditing(node) {
        if (!(node.type.includes('chart') || node.type === 'maps')) {
            this.nodeViewModel.set(node);
            this.nodesStore.update(node.id, { editing: true });
        }
    }

    updateChart(node) {
        if (node.type.includes('chart')) {
            this.chartsService.loadChartAndRegenerateFailed(node.id, 5).subscribe();
        }
        if (node.type === 'maps') {
            const mapPayload = new RequestDto({ mapId: node.id }, {}, {});
            this.mapService.loadImageForMap(mapPayload).subscribe();
        }
    }

    updateNode(payload: NodeDto): Observable<Node> {
        this.nodesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/nodes/${payload.getParam('nodeId')}`;

        return this.http.patch<Node>(url, payload.body, { params: payload.queryParams }).pipe(
            tap((node) => {
                this.nodesStore.update(node.id, node);
                this.nodesStore.setLoading(false);
                this.updateChart(node);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    moveNode(payload: any): Observable<Node[]> {
        this.nodesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/nodes/${payload.getParam('nodeId')}/position/${payload.getParam(
            'position'
        )}`;

        return this.http.post<Node[]>(url, {}).pipe(
            tap((nodes) => {
                this.nodesStore.upsertMany(nodes);
                this.nodesStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    deleteNode(payload: any): Observable<any> {
        this.nodesStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/nodes/${payload.getParam('nodeId')}`;

        return this.http.delete<Node>(url, {}).pipe(
            tap((emptyResponse) => {
                this.nodesStore.remove(payload.getParam('nodeId'));
                this.nodesStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    deselectNode(node): Observable<Node> {
        const payload = new NodeDto({ nodeId: node.id }, {}, {});
        return this.updateNode(payload).pipe(
            tap((updatedNode: Node) => {
                this.nodesStore.update(updatedNode.id, { editing: false });
                this.nodesStore.update({ selectedNode: undefined });
                this.uiService.setNodeHeight(100);
                this.nodeViewModel.reset();
            })
        );
    }

    deselect(node): void {
        this.nodesStore.update(node.id, { editing: false });
        this.nodesStore.update({ selectedNode: undefined });
        this.uiService.setNodeHeight(100);
        this.nodeViewModel.reset();
    }

    selectNode(selectedElement) {
        const node = selectedElement.element as Node;
        const selectedNode = this.nodesQuery.getValue().selectedNode;
        if (selectedNode) {
            this.deselectNode(node).subscribe(() => {
                this.nodeViewModel.set(node);
                this.nodesStore.update(node.id, { editing: true });
            });
        } else {
            this.nodeViewModel.set(node);
            this.nodesStore.update(node.id, { editing: true });
            this.nodesStore.update({ selectedNode: node });
        }
    }

    updateContent(node, content) {
        this.nodesStore.update(node.id, { content });
    }

    updateRenderedContentInStore(node: Node): void {
        if (node.type !== NodeType['excel-table']) {
            return;
        }

        const renderedContent = typeof node.renderedContent === 'string' ? node.renderedContent : '';
        this.nodesStore.update(node.id, { renderedContent });
    }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImagesQuery } from '../../../editor-container/state/images/images.query';
import { AspectRatiosQuery } from '../../../../modules/shared/state/aspect-ratios/aspect-ratios.query';
import { Observable } from 'rxjs';
import { exhaustMap, filter, map } from 'rxjs/operators';
import { Image } from '../../../editor-container/models/image.model';
import { RequestDto } from '../../models/dto/request-dto.model';
import { ImagesService } from '../../../editor-container/services/images.service';

@Component({
    selector: 'elias-section-background-image',
    styleUrls: ['./section-background-image.component.scss'],
    templateUrl: './section-background-image.component.html',
})
export class SectionBackgroundImageComponent implements OnChanges {
    @Input() id?: string;

    public backgroundImagePath$?: Observable<string | SafeUrl>;

    constructor(
        private aspectRatiosQuery: AspectRatiosQuery,
        private imagesQuery: ImagesQuery,
        private imagesService: ImagesService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['id']) {
            this.updateImagePath();
        }
    }

    private updateImagePath(): void {
        const aspectRatioId = this.getOriginalAspectRatio();

        if (!this.id || !aspectRatioId) {
            return;
        }

        this.backgroundImagePath$ = this.imagesQuery
            .selectEntity(this.id)
            .pipe(map((image) => this.getImageUrl(image)));

        this.backgroundImagePath$
            .pipe(
                filter((path) => path === ''),
                exhaustMap(() =>
                    this.imagesService.loadSingleImage(
                        new RequestDto({
                            assetId: this.id,
                            aspectRatio: aspectRatioId,
                        })
                    )
                )
            )
            .subscribe();
    }

    private getImageUrl(image?: Image): string | SafeUrl {
        if (!image || typeof image.aspectRatios !== 'object') {
            return '';
        }
        const aspectRatioId = this.getOriginalAspectRatio();

        if (aspectRatioId && image.aspectRatios[aspectRatioId]) {
            return this.sanitizer.bypassSecurityTrustStyle(`url(${image.aspectRatios[aspectRatioId].content})`);
        }

        return '';
    }

    private getOriginalAspectRatio(): string | null {
        const allAspectRatios = this.aspectRatiosQuery.getAll();

        return allAspectRatios.filter((a) => a.factor == '0')[0].id;
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { Node } from '../../../models/node/node.model';
import { Observable } from 'rxjs';
import { LineNode } from '../../../models/node/line-node.model';
import { NodeType } from '../../../models/node/node-type.model';

@Component({
    selector: 'elias-editor-display-line',
    templateUrl: './display-line.component.html',
})
export class DisplayLineComponent implements NodeRepresentation, OnInit {
    @Input() config: { type: NodeType; settings?: any };
    @Input() content: string;
    @Input() node: LineNode;
    @Input() nodeViewModel: Observable<Node>;

    color: string = '';

    ngOnInit() {
        this.config = this.config || { type: 'page-break' } || { type: 'col-break' };
        this.color = this.getCorrectFormat(this.node.color ?? '');
    }

    getCorrectFormat(value: string | undefined): string {
        if (value && value.includes('#')) {
            return value;
        }

        return '#' + value;
    }
}

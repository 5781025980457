import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Asset } from '../models/asset.model';
import { RequestDto } from '../../../editor/editor/models/dto/request-dto.model';
import { AssetsStore } from '../state/assets/assets.store';
import { DomainService } from '../../core/services/domain.service';
import { withTransaction } from '@datorama/akita';
import { Excel } from '../../../editor/editor/models/excel.model';
import { ImagesService } from '../../../editor/editor-container/services/images.service';
import { PublicationUsingAsset } from '../models/publication-using-asset.model';

@Injectable()
export class AssetsService {
    constructor(
        private assetsStore: AssetsStore,
        private domainService: DomainService,
        private http: HttpClient,
        private imagesService: ImagesService
    ) {}

    loadSingleAsset(payload: RequestDto): Observable<Asset> {
        this.assetsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/assets/${payload.getParam('assetId')}`;

        return this.http.get<Asset>(url, {}).pipe(
            withTransaction((asset) => {
                if (asset.thumbnail) {
                    asset.thumbnail.content = URL.createObjectURL(
                        this.imagesService.b64toBlob(asset.thumbnail.content)
                    );
                }

                this.assetsStore.upsert(asset.id, asset);
                this.assetsStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getPublicationsUsingAsset(assetId: string, ignoredNodeId?: string): Observable<PublicationUsingAsset[]> {
        let url = `${this.domainService.apiBaseUrl}/assets/${assetId}/publications`;

        if (ignoredNodeId) {
            url += `?ignoredNodeId=${ignoredNodeId}`;
        }

        return this.http.get<PublicationUsingAsset[]>(url);
    }

    isImage(asset: Asset): boolean {
        return asset.mimeType.includes('image');
    }

    isExcel(asset: Asset): asset is Excel {
        let allowedTypes = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/octet-stream',
        ];
        return allowedTypes.includes(asset.mimeType);
    }

    hasError(asset: Asset | undefined): boolean {
        if (!asset) {
            return false;
        }

        if (this.isExcel(asset)) {
            return asset.processingError;
        }

        return false;
    }
}

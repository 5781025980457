import { Component, Input } from '@angular/core';

type ChannelIconSize = 'xsmall' | 'small' | 'medium' | 'large';
@Component({
    selector: 'elias-shared-channel-icon',
    templateUrl: './channel-icon.component.html',
    styleUrls: ['./channel-icon.component.scss'],
})
export class ChannelIconComponent {
    @Input() icon?: string;
    @Input() size: ChannelIconSize = 'medium';

    constructor() {}
}
